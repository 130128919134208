import React from "react";
import { Link } from "react-router-dom";
import { aboutUs, socials } from "../../data/footer";

const Footer = () => {
  return (
    <footer className="margin-top-3">
      <div className=" py-[1.3rem] bg-secondary-50 font-poppins text-white">
        <div className="wrapper grid grid-cols-1 gap-y-4 text-center md:text-left md:grid-cols-3 md:gap-x-4">
          <div className="col-span-1">
            <a href="/" className="grid">
              <h5 className="text-sm font-bold">Hope Pharma</h5>
              <h6 className="text-[0.7rem] font-normal text-gray-400">
                Connections
              </h6>
            </a>
            <p className="mt-4 text-[10px] font-light px-7 text-gray-400 md:px-0">
              Hope Pharma Connections is an online based medicine supplying
              service focused on providing safe and affordable lifesaving
              prescription medicines globally with a primary focus on Australia
              and New Zealand. We are specialised in sourcing and supplying
              anti-cancer medicines. Most kind of generic prescription medicine
              are also available in our servcie portfolio.
            </p>
          </div>
          <div className="col-span-1">
            <h5 className="text-sm font-bold">More about us:</h5>
            <ul className="mt-2 md:mt-6">
              {aboutUs.map((about) => (
                <li key={about.title}>
                  <Link
                    to={about.path}
                    className="text-[10px] font-light text-gray-400 hover:underline"
                  >
                    {about.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-1">
            <h5 className="text-sm font-bold">Contact us:</h5>
            <ul className="mt-2 md:mt-6">
              <li className="text-[10px] font-light text-gray-400">
                Phone:{" "}
                <a href="tel:+6496664673" className="text-white">
                  +64-9-666-HOPE(4673)
                </a>
              </li>
              <li className="text-[10px] font-light text-gray-400">
                Email:{" "}
                <a
                  href="mailto:info@hopepharmaconnections.co.nz"
                  className="text-white"
                >
                  info@hopepharmaconnections.co.nz
                </a>
              </li>
            </ul>
            <ul className="mt-5 flex gap-x-2 justify-center md:justify-start">
              {socials.map((social) => (
                <li key={social.title}>
                  <a target="_blank" href={social.path} rel="noreferrer">
                    <img
                      src={`/social/${social.icon}`}
                      alt={social.title}
                      className="w-6 h-6"
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center py-2.5 bg-black text-white text-[10px] px-24">
        Auckland 0625 New Zealand.
      </div>
    </footer>
  );
};

export default Footer;
