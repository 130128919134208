import React from "react";

const DescriptionHeader = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="mt-1 grid grid-cols-12">
      <div className="col-span-4 px-2.5 py-5 bg-primary-100 font-bold text-[8px] capitalize">
        {label}
      </div>
      <div className="col-span-8 pl-2 pr-3 py-1 bg-primary-50 font-medium text-[8px]">
        {value}
      </div>
    </div>
  );
};

export default DescriptionHeader;
