import Layout from "../layout";
import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import axios from "../../helper/axios";
import { useGlobalContext } from "../../hooks/context";
import InquiyInput from "../ui/InquiyInput";
import ErrorMessage from "../ui/ErrorMessage";

const schema = yup.object({
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("E-mail is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

const AuthCheck = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth, authUpdate } = useGlobalContext();
  const [submitting, setSubmitting] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { email, password } = errors;

  const navigatePath = location.pathname + location.search;

  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    try {
      setErrorMessage("");
      setSubmitting(true);
      const { data, status } = await axios.post("/auth/login", payload);
      if (status === 404 || status === 401) {
        setErrorMessage(data.message);
      }

      if (status === 200) {
        reset();
        authUpdate(data.user);
        navigate(navigatePath);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return auth ? (
    <Outlet />
  ) : (
    <Layout>
      <div className="wrapper font-poppins">
        <div className="py-5">
          <h2 className="text-lg font-medium text-center">
            You can't acces this page before login.
            <br />
          </h2>
          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-3 ">
              <InquiyInput
                name="email"
                type="email"
                placeholder="Email Address"
                register={register}
                message={email?.message}
              />

              <InquiyInput
                name="password"
                type="password"
                placeholder="Password"
                register={register}
                message={password?.message}
                eyeIconShow
              />
            </div>
            <div className="text-right mt-3">
              <button
                type="submit"
                className="text-secondary-50 hover:bg-secondary-50 border-secondary-50 hover:text-white rounded-[20px] border font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
                disabled={submitting}
              >
                Login
              </button>
            </div>
            <div className="text-right mt-3">
              <ErrorMessage message={errMessage} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AuthCheck;
