import React from "react";
import HyperModal from "react-hyper-modal";
import LoginForm from "./LoginForm";

const LoginModal = ({ isOpen, controlModal }) => {
  return (
    <HyperModal isOpen={isOpen} requestClose={() => controlModal()}>
      <LoginForm controlModal={controlModal} />
    </HyperModal>
  );
};

export default LoginModal;
