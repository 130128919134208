import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/context";
import DescriptionHeader from "./DescriptionHeader";

const ProductDescription = ({ product }) => {
  const { addSelectedItem } = useGlobalContext();
  const navigate = useNavigate();

  const handleInquiry = () => {
    const payload = { id, name, generic, orginator };
    addSelectedItem(payload);

    navigate("/products/inquiry");
  };
  const {
    id,
    name,
    generic,
    orginator,
    dosage,
    sideEffect,
    prescribingInformation,
    faqs,
    indications,
    pdDescription,
    application,
    formulation,
    availableStrength,
    availablePackSize,
  } = product || {};

  return (
    <div className="wrapper margin-top-3 font-poppins">
      <div className="">
        <div className="w-full p-2.5 bg-primary-50 relative">
          <button
            onClick={handleInquiry}
            className="absolute bottom-2 right-2 outline-0  px-2 py-0.5 bg-white rounded-full font-bold text-[12px] focus:outline-none cursor-pointer hover:border border-primary-100"
          >
            Inquiry for Price
          </button>
          <h5 className="text-[10px] font-bold text-black">{name}</h5>
          <p className="mt-0.5 text-[8px] font-semibold text-black">
            Generic:
            <span className="font-medium"> {generic}</span>
          </p>
          <p className="mt-0.5 text-[8px] font-semibold text-black">
            Packaging:
            <span className="font-medium">
              {availablePackSize?.map((availablePack) => (
                <p>
                  {availablePackSize.size} {availablePack.packSize},
                </p>
              ))}
            </span>
          </p>
          <p className="mt-0.5 text-[8px] font-semibold text-black">
            Originator:
            <span className="font-medium"> {orginator}</span>
          </p>
          <p className="mt-0.5 text-[8px] font-semibold text-black">
            Formulation:
            <span className="font-medium"> {formulation}</span>
          </p>
          <p className="mt-0.5 text-[8px] font-semibold text-black">
            Availabe Strength:
            <span className="font-medium"> {availableStrength}</span>
          </p>
        </div>

        <DescriptionHeader label="Indication" value={indications} />
        <DescriptionHeader label="Applications" value={application} />
        <DescriptionHeader label="Dosage" value={dosage} />
        <DescriptionHeader label="Side Effects" value={sideEffect} />
        <DescriptionHeader
          label="Prescribing Information"
          value={prescribingInformation}
        />
        <DescriptionHeader label="Product Description" value={pdDescription} />
        <DescriptionHeader label="FAQs" value={faqs} />
        <DescriptionHeader
          label="How To Order"
          value="To Order please go through the Inquiry for Price button"
        />

        <div className="mt-1 flex justify-end">
          <div className="flex justify-between gap-x-10">
            <a
              href="mailto:info@hopepharmaconnections.co.nz"
              className="outline-0 border-0 px-2 py-0.5 bg-[#D9D9D9] rounded-full font-bold text-[8px] focus:outline-none"
            >
              Quick Email
            </a>
            <a
              href="tel:+6496664673"
              className="outline-0 border-0 px-2 py-0.5 bg-[#D9D9D9] rounded-full font-bold text-[8px] focus:outline-none"
            >
              Quick Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
