import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InquiyInput from "../../components/ui/InquiyInput";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { countryList } from "../../data/country";

const PatientForm = () => {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    if (checked) {
      setValue("deliveryFullAddress", "");
      setValue("deliverySuburb", "");
      setValue("deliveryCity", "");
      setValue("deliveryPostCode", "");
      setValue("deliveryCountry", "");
    } else {
      // Copy
      setValue("deliveryFullAddress", getValues().contactFullAddress);
      setValue("deliverySuburb", getValues().contactSuburb);
      setValue("deliveryCity", getValues().contactCity);
      setValue("deliveryPostCode", getValues().contactPostCode);
      setValue("deliveryCountry", getValues().contactCountry);
    }

    setChecked(!checked);
  };

  const {
    firstName,
    lastName,
    email,
    phone,
    contactFullAddress,
    contactSuburb,
    contactCity,
    contactPostCode,
    contactCountry,
    deliveryFullAddress,
    deliverySuburb,
    deliveryCity,
    deliveryPostCode,
    deliveryCountry,
    prescription,
  } = errors;

  return (
    <div className="margin-top-3 font-poppins wrapper ">
      <div className="bg-[#EAD2E5] px-3 py-2 rounded">
        <div>
          <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">
            Patient’s Details
          </label>
          <div className="grid grid-cols-2 gap-x-2">
            <InquiyInput
              name="firstName"
              type="text"
              placeholder="First Name"
              register={register}
              message={firstName?.message}
            />
            <InquiyInput
              name="lastName"
              type="text"
              placeholder="Last Name"
              register={register}
              message={lastName?.message}
            />
          </div>
        </div>
        <div className="mt-3">
          {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Patient Contact Details
          </label> */}
          <div className="grid grid-cols-2 gap-x-2">
            <InquiyInput
              name="email"
              type="email"
              placeholder="Email Address"
              register={register}
              message={email?.message}
            />
            <div className="col-span-1">
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    defaultCountry="nz"
                    country="nz"
                    enableSearch={true}
                    disableSearchIcon={true}
                    countryCodeEditable={false}
                    onChange={(phone) => onChange(phone)}
                  />
                )}
              />
              {phone?.message && <ErrorMessage message={phone?.message} />}
            </div>
          </div>
        </div>
        <div className="mt-3">
          {/* <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">
            Contact Address:
          </label> */}
          <InquiyInput
            name="contactFullAddress"
            type="text"
            placeholder="Full address (incl flat/plot/house number, & Street name"
            register={register}
            disabled={checked}
            message={contactFullAddress?.message}
            height="50px"
          />
          <div className="grid grid-cols-2 gap-2">
            <InquiyInput
              name="contactSuburb"
              type="text"
              placeholder="Suburb"
              register={register}
              disabled={checked}
              message={contactSuburb?.message}
            />
            <InquiyInput
              name="contactCity"
              type="text"
              placeholder="City"
              register={register}
              disabled={checked}
              message={contactCity?.message}
            />
            <InquiyInput
              name="contactPostCode"
              type="text"
              placeholder="Post code"
              register={register}
              disabled={checked}
              message={contactPostCode?.message}
            />
            <div className="col-span-1">
              <Controller
                name="contactCountry"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <select
                      className="input-group"
                      placeholder="Seclect Country"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      disabled={checked}
                    >
                      <option value="" hidden>
                        Select Country
                      </option>
                      {countryList.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {contactCountry?.message && (
                      <ErrorMessage message={contactCountry?.message} />
                    )}
                  </>
                )}
              />
            </div>

            {/* <InquiyInput
              name="contactCountry"
              type="text"
              placeholder="Country"
              register={register}
              message={contactCountry?.message}
            /> */}
          </div>
        </div>
        <div className="mt-3">
          <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">
            Delivery Address
          </label>

          <div className="mb-5 flex gap-x-3">
            <label
              htmlFor="same"
              className="block uppercase tracking-wide text-gray-900 text-xs font-medium cursor-pointer"
            >
              (Same as Patient Details)?
            </label>
            <input
              id="same"
              type="checkbox"
              name="delivery_address"
              className="mt-0 cursor-pointer w-3.5 h-3.5"
              checked={checked}
              onChange={handleChange}
            />
          </div>

          <InquiyInput
            name="deliveryFullAddress"
            type="text"
            placeholder="Full address (incl flat/plot/house number, & Street name"
            register={register}
            message={deliveryFullAddress?.message}
            height="50px"
          />
          <div className="grid grid-cols-2 gap-2">
            <InquiyInput
              name="deliverySuburb"
              type="text"
              placeholder="Suburb"
              register={register}
              message={deliverySuburb?.message}
            />
            <InquiyInput
              name="deliveryCity"
              type="text"
              placeholder="City"
              register={register}
              message={deliveryCity?.message}
            />
            <InquiyInput
              name="deliveryPostCode"
              type="text"
              placeholder="Post code"
              register={register}
              message={deliveryPostCode?.message}
            />
            <div className="col-span-1">
              <Controller
                name="deliveryCountry"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <>
                    <select
                      name={name}
                      className="input-group"
                      placeholder="Seclect Country"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <option value="" hidden>
                        Select Country
                      </option>
                      {countryList.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {deliveryCountry?.message && (
                      <ErrorMessage message={deliveryCountry?.message} />
                    )}
                  </>
                )}
              />
            </div>
            {/* <InquiyInput
              name="deliveryCountry"
              type="text"
              placeholder="Country"
              register={register}
              message={deliveryCountry?.message}
            /> */}
          </div>
        </div>
        <div className="mt-3">
          <label className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">
            Attach Prescription
          </label>
          <input
            type="file"
            name="prescription"
            className="text-xs"
            {...register("prescription")}
          />
          {prescription?.message && (
            <ErrorMessage message={prescription?.message} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientForm;
