import React, { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import ErrorMessage from "./ErrorMessage";

const InquiyInput = ({
  name,
  type,
  placeholder,
  register,
  message,
  disabled,
  height,
  eyeIconShow = false,
}) => {
  const [inputType, setInputType] = useState(type || "text");

  const handleTypeToggle = () => {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  };

  return (
    <div className="col-span-1 relative">
      <input
        name={name}
        className="input-group"
        type={inputType}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name)}
        style={{ height: height }}
      />
      {eyeIconShow && (
        <button
          type="button"
          className="absolute top-0 right-0 p-2 hover:bg-gray-500"
          onClick={handleTypeToggle}
        >
          {inputType === "password" ? <IoMdEye /> : <IoMdEyeOff />}
        </button>
      )}

      {message && <ErrorMessage message={message} />}
    </div>
  );
};

export default InquiyInput;
