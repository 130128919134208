import { BiGridAlt, BiDockTop, BiBuilding } from "react-icons/bi";

export const sidebarData = [
  {
    text: "Dashboard",
    path: "/admin/dashboard",
    Icon: BiGridAlt,
  },
  {
    text: "Products",
    path: "/admin/products",
    Icon: BiDockTop,
  },
  {
    text: "Orders",
    path: "/admin/orders",
    Icon: BiBuilding,
  },
];
