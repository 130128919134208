import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import AdminInput from "../shared/admin/AdminInput";
import { BiSolidTrash } from "react-icons/bi";
import ProductImage from "./ProductImage";
import ErrorMessage from "../ui/ErrorMessage";

const AvailablePackSize = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "availablePackSize",
    rules: {
      required: true,
      minLength: 1,
    },
  });

  const handleAddMoreFields = () => {
    prepend({
      size: "",
      packSize: "",
      packName: "",
      price: 0,
      packImage: null,
    });
  };

  const handleDeleteField = (index) => {
    remove(index);
  };

  const handleImageUpload = (imageUrl, index) => {
    setValue(`availablePackSize.${index}.packImage`, imageUrl);
  };

  const { availablePackSize = [] } = errors || {};

  return (
    <div className="mt-5 md:mt-10">
      <div className="flex items-center justify-between md:justify-start md:gap-x-5">
        <p className="text-xl font-semibold text-gray-800">
          Available Pack Size
        </p>
        <button
          type="button"
          className="bg-red-400 text-white py-0.5 rounded px-2"
          onClick={handleAddMoreFields}
        >
          Add More
        </button>
      </div>

      {errors?.availablePackSize?.message && (
        <ErrorMessage message={errors?.availablePackSize?.message} />
      )}

      <div className="mt-5 grid grid-cols-1 gap-y-5">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="bg-gray-300 p-3 rounded grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-5"
          >
            <Controller
              control={control}
              name={`availablePackSize.${index}.size`}
              render={({ field: { onChange, value, name } }) => (
                <AdminInput
                  id={name}
                  name={name}
                  type="text"
                  label="Strength"
                  value={value || ""}
                  onChange={onChange}
                  error={availablePackSize[index]?.size}
                />
              )}
            />
            <Controller
              control={control}
              name={`availablePackSize.${index}.packSize`}
              render={({ field: { onChange, value, name } }) => (
                <AdminInput
                  id={name}
                  name={name}
                  type="text"
                  label="Pack Size"
                  value={value || ""}
                  onChange={onChange}
                  error={availablePackSize[index]?.packSize}
                />
              )}
            />
            <Controller
              control={control}
              name={`availablePackSize.${index}.packName`}
              render={({ field: { onChange, value, name } }) => (
                <AdminInput
                  id={name}
                  name={name}
                  type="text"
                  label="Pack Name"
                  value={value || ""}
                  onChange={onChange}
                  error={availablePackSize[index]?.packName}
                />
              )}
            />
            <Controller
              control={control}
              name={`availablePackSize.${index}.price`}
              render={({ field: { onChange, value, name } }) => (
                <AdminInput
                  id={name}
                  name={name}
                  type="number"
                  label="Price"
                  value={value || ""}
                  onChange={onChange}
                  error={availablePackSize[index]?.price}
                />
              )}
            />
            <div className="flex items-center gap-x-2 mt-5">
              <ProductImage
                callback={(imageUrl) => handleImageUpload(imageUrl, index)}
                defaultImageUrl={fields[index]?.packImage}
                error={availablePackSize[index]?.packImage}
              />
              <button
                className="text-red-500 cursor-pointer"
                onClick={() => handleDeleteField(index)}
              >
                <BiSolidTrash />
              </button>
              {/* {index !== 0 && (
                <button
                  className="text-red-500 cursor-pointer"
                  onClick={() => handleDeleteField(index)}
                >
                  <BiSolidTrash />
                </button>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailablePackSize;
