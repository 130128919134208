import Select from "react-select";
import ErrorMessage from "../../ui/ErrorMessage";

const AdminMultiSelect = ({
  id,
  name,
  label,
  value,
  options,
  onChange,
  error,
  ...rest
}) => {
  return (
    <div className="col-span-1">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-semibold text-gray-900"
        >
          {label}
        </label>
      )}

      <Select
        id={name}
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        isMulti={true}
        {...rest}
      />
      {!!error && <ErrorMessage message={error?.message} />}
    </div>
  );
};

export default AdminMultiSelect;
