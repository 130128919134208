import React from "react";
import assests from "../../assests";

const Product = ({ product, packSizeHandler, increase, decrease }) => {
  const { id, name, availablePackSize, price, qty, subTotal } = product || {};
  return (
    <div className="mt-3 grid grid-cols-11 gap-x-1 py-5 px-1 rounded bg-white text-black">
      <span className="col-span-3 text-xs font-normal text-left">{name}</span>
      <span className="col-span-2 text-xs font-normal text-left">
        <select
          className="w-full bg-transparent"
          onChange={(e) => packSizeHandler(e.target.value, product)}
        >
          {availablePackSize?.map((packSize) => (
            <option key={packSize.size} value={packSize.size}>
              {packSize.size}
            </option>
          ))}
        </select>
      </span>
      <span className="col-span-2 text-xs font-normal text-left">
        $ {price.toFixed(2)}
      </span>
      <div className="col-span-2 text-xs font-normal text-left flex items-center gap-x-0.5">
        <button
          type="button"
          className="h-3 w-3 outline-none"
          onClick={() => decrease(id)}
          disabled={qty === 1}
        >
          <img
            src={assests.decreaseSvg}
            alt="decrease"
            className="w-full h-full"
          />
        </button>
        {qty}
        <button
          type="button"
          className="h-3 w-3 outline-none"
          onClick={() => increase(id)}
        >
          <img
            src={assests.increaseSvg}
            alt="decrease"
            className="w-full h-full"
          />
        </button>
      </div>
      <span className="col-span-2 text-xs font-normal text-right">
        $ {subTotal.toFixed(2)}
      </span>
    </div>
  );
};

export default Product;
