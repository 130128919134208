import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import Search from "../../components/shared/Search";
import LoginLine from "../../components/shared/LoginLine";
import ProductCard from "../../components/shared/ProductCard";
import { useParams } from "react-router-dom";
// import allProducts from "../../data/productss";
import { useGlobalContext } from "../../hooks/context";
import axiosInstance from "../../helper/axios";
import getErrorMsg from "../../utils/getErrorMsg";
import ErrorMessage from "../../components/ui/ErrorMessage";

// const keys = ["name", "category", "generic", "orginator"];

const Products = () => {
  const { category } = useParams();
  const { searchValue } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allProducts, setAllProducts] = useState([]);

  let url = "/products";
  if (category !== "all") {
    url += `?category=${category.toLowerCase()}&searchTerm=${searchValue}`;
  } else {
    url += `?searchTerm=${searchValue}`;
  }

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get(url);
        setAllProducts(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, [category, searchValue, url]);

  // const filterProducts = (data) => {
  //   let products = [];

  //   if (category !== "all") {
  //     products = data.filter((product) => {
  //       return product.category.toLowerCase() === category.toLowerCase();
  //     });
  //   } else if (searchValue) {
  //     products = data.filter((product) => {
  //       return keys.some((key) =>
  //         product[key].toLowerCase().includes(searchValue)
  //       );
  //     });
  //   } else {
  //     products = allProducts;
  //   }

  //   return products;
  // };

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else if (!isLoading && !error && allProducts?.length === 0) {
    content = <ErrorMessage message="There is no Medicine!" />;
  } else {
    content = (
      <div className="my-12 grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-5 xl:grid-cols-4">
        {/* {filterProducts(allProducts).map((product) => (
          <ProductCard key={product._id} product={product} />
        ))} */}
        {allProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    );
  }

  return (
    <Layout>
      <LoginLine />
      <Search />
      <div className="margin-top-3 wrapper font-poppins">
        <h5 className="mt-12 text-[14px] font-bold text-black capitalize">
          {category} Medicine
        </h5>
        {content}
      </div>
    </Layout>
  );
};

export default Products;
