export const aboutUs = [
  {
    title: "Terms and conditions",
    path: "/terms-conditions",
  },
  {
    title: "Disclaimer",
    path: "/disclaimer",
  },
  {
    title: "Refund Policy",
    path: "/refund-policy",
  },
  // {
  //   title: "Shipping Policies",
  //   path: "/shipping-policy",
  // },
  {
    title: "About Us",
    path: "/about-us",
  },
  // {
  //   title: "FAQs",
  //   path: "/faqs",
  // },
  {
    title: "Admin Login",
    path: "/login",
  },
];

export const socials = [
  {
    title: "FaceBook",
    icon: "facebook.svg",
    path: "https://www.facebook.com/profile.php?id=61556089477577",
  },
  {
    title: "WhatsApp",
    icon: "whatsapp.svg",
    path: "https://wa.me/message/CMR7SXMMTVNGL1",
  },
  // {
  //   title: "Twitter",
  //   icon: "twitter.svg",
  //   path: "/",
  // },
  {
    title: "Linkedin",
    icon: "linkedin.svg",
    path: "http://tinyurl.com/55tmch63",
  },
  // {
  //   title: "Instagram",
  //   icon: "instagram.svg",
  //   path: "/",
  // },
];
