import React from "react";
import AdminLayout from "../../../components/layout/AdminLayout";

const AdminDashboard = () => {
  return (
    <AdminLayout>
      <h1 className="text-3xl text-gray-800">Dashboard</h1>
    </AdminLayout>
  );
};

export default AdminDashboard;
