export const getQueryParams = (query = null) => {
  return (
    (query || window.location.search.replace("?", ""))

      // get array of KeyValue pairs
      .split("&")

      // Decode values
      .map((pair) => {
        let [key, val] = pair.split("=");

        return [key, decodeURIComponent(val || "")];
      })

      // array to object
      .reduce((result, [key, val]) => {
        result[key] = val;
        return result;
      }, {})
  );
};
