import React from "react";

const Rating = ({ rating }) => {
  let ratingArray = [];
  for (let i = 0; i < rating; i++) {
    ratingArray.push(i);
  }
  return (
    <div className="flex gap-x-1">
      {ratingArray.map((rating) => (
        <img
          key={rating}
          src="/icon/star.svg"
          alt="Rating"
          className="w-[10px] h-[10px]"
        />
      ))}
    </div>
  );
};

export default Rating;
