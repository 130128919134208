import React from "react";
import Layout from "../layout";

const RefundPolicy = () => {
  return (
    <Layout>
      <div className="p-4 xl:p-20 mb-20">
        <h3 className="text-2xl font-semibold mb-8">Refund Policy</h3>{" "}
        <h3>
          Thank you for looking at specific refund policy for Hope Pharma
          Connections Limited. Please appreciate that due to the complexities
          and costs associated with customs procedures and duties, refunds are
          not possible once the shipment is handed over to DHL. If the shipment
          has not been handed over to DHL, a refund may be considered upon
          approval from the supplier. We will deduct Bank fees and NZD
          conversion loss. The refund will be paid to a bank account only, and
          no other refund options are available. It's important for our
          customers to carefully consider their purchases and make informed
          decisions while buying through Hope Pharma Connections Limited,
          considering the limitations and conditions outlined in our refund
          policy. If you have any further questions or require clarification, I
          recommend reaching out to Hope Pharma Connections Limited directly for
          assistance.
        </h3>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
