const getErrorMsg = (error) => {
  let message;
  if (error?.response) {
    message = error.response?.data?.message || "Something went wrong!";
  } else {
    message = error?.message;
  }

  return message;
};

export default getErrorMsg;
