import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/layout/AdminLayout";
import axiosInstance from "../../../helper/axios";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import getErrorMsg from "../../../utils/getErrorMsg";
import { useGlobalContext } from "../../../hooks/context";
import EditButton from "../../../components/shared/EditButton";

const OrderList = () => {
  const { auth } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get("/orders", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setOrders(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchOrders();
  }, [auth?.token]);

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else {
    content = (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Invoice
            </th>
            <th scope="col" className="px-6 py-3">
              Products Name
            </th>
            <th scope="col" className="px-6 py-3">
              Payment Status
            </th>
            <th scope="col" className="px-6 py-3">
              Total Price
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((order) => (
            <tr key={order._id || order.id} className="bg-white border-b">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {order.invoiceId}
              </th>
              <td className="px-6 py-4">
                {order.products?.map((product, index) => (
                  <p key={index}>
                    {index + 1}. {product.name}
                  </p>
                ))}
              </td>
              <td className="px-6 py-4">{order.payment?.status}</td>
              <td className="px-6 py-4">{order.totalPrice}</td>
              <td className="px-6 py-4 flex gap-x-2">
                <EditButton path={`/admin/orders/${order._id}/details`} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <AdminLayout>
      <div className="py-3 border-b">
        <h3 className="text-2xl font-semibold text-gray-800">Orders</h3>
      </div>

      <div className="relative overflow-x-auto">{content}</div>
    </AdminLayout>
  );
};

export default OrderList;
