import ErrorMessage from "../../ui/ErrorMessage";

export default function AdminInput({
  id,
  name,
  label,
  type,
  onChange,
  error,
  ...rest
}) {
  const handleChange = (e) => {
    if (type === "number") {
      onChange(Number(e.target.value));
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <div className="col-span-1">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-semibold text-gray-900"
        >
          {label}
        </label>
      )}
      <input
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        id={id}
        name={name}
        onChange={handleChange}
        {...rest}
      />
      {!!error && <ErrorMessage message={error?.message} />}
    </div>
  );
}
