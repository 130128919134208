import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import reviews from "../../data/reviews";
//import productss from "../../data/productss";
import Layout from "../../components/layout";
//import Review from "./Review";
import DetailsBanner from "./DetailsBanner";
import ProductDescription from "./Description";
import Search from "../../components/shared/Search";
import LoginLine from "../../components/shared/LoginLine";
import Categories from "../../components/shared/Categories";
import axiosInstance from "../../helper/axios";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import getErrorMsg from "../../utils/getErrorMsg";

import "../../css/productSlider.css";

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get(`/products/${productId}`);
        setProduct(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, [productId]);

  // const product = productss.find((product) => product._id === productId);
  // const filterReviews = reviews.filter(
  //   (review) => review.productId === productId
  // );

  // Decide what to render
  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else if (!isLoading && !error && !product) {
    content = <ErrorMessage message="Product not found!" />;
  } else {
    content = (
      <>
        <DetailsBanner product={product} />
        <ProductDescription product={product} />
        {/* <Review reviews={filterReviews} /> */}
        <Categories />
      </>
    );
  }

  return (
    <Layout>
      <LoginLine />
      <Search />
      {content}
    </Layout>
  );
};

export default ProductDetails;
