import React, { useState } from "react";
import AdminNavbar from "../header/AdminNavbar";
import AdminSidebar from "../sidebar/AdminSidebar";

const AdminLayout = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleToogle = (toggleValue) => {
    setShow(toggleValue);
  };

  return (
    <div className="">
      <AdminNavbar show={show} onToggle={handleToogle} />
      <AdminSidebar show={show} onToggle={handleToogle} />
      <main className="p-4 ml-0 min-h-screen font-poppins bg-[#f5f7fa] xl:ml-[15rem]">
        {children}
      </main>
    </div>
  );
};

export default AdminLayout;
