import React, { useState } from "react";
import Register from "../../components/auth/Register";
import LoginModal from "../../components/auth/LoginModal";

const InquiryButton = () => {
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  // control login modal
  const controlLoginModal = () => {
    setLoginModal((prev) => !prev);
  };

  // control register modal
  const controlRegisterModal = () => {
    setRegisterModal((prev) => !prev);
  };

  return (
    <>
      <div className="flex gap-x-3">
        <button
          className="w-full margin-top-3 px-3 py-2 bg-primary-50 border-red-300 border rounded text-gray-600 font-medium text-sm"
          onClick={controlLoginModal}
        >
          Login for inquiry
        </button>
        <button
          className="w-full margin-top-3 px-3 py-2 bg-primary-50 border-red-300 border rounded text-gray-600 font-medium text-sm"
          onClick={controlRegisterModal}
        >
          Register for inquiry
        </button>
      </div>
      <LoginModal isOpen={loginModal} controlModal={controlLoginModal} />
      <Register isOpen={registerModal} controlModal={controlRegisterModal} />
    </>
  );
};

export default InquiryButton;
