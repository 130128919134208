import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/layout/AdminLayout";
import ListHeader from "../../../components/shared/ListHeader";
import { useGlobalContext } from "../../../hooks/context";
import axiosInstance from "../../../helper/axios";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import getErrorMsg from "../../../utils/getErrorMsg";

const Orderdetails = () => {
  const { id } = useParams();
  const { auth } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    async function fetchOrder() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get(`/orders/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setOrder(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchOrder();
  }, [auth?.token, id]);

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else if (!isLoading && !error && !order) {
    content = <ErrorMessage message="Order Not Found!" />;
  } else {
    const {
      patient,
      contactAddress,
      deliveryAddress,
      payment,
      invoiceId,
      products,
      shippingPrice,
      bankChargePrice,
      gstPrice,
      totalPrice,
    } = order || {};

    const productsPrice = products?.reduce(function (acc, curr) {
      return acc + curr.subTotal;
    }, 0);

    content = (
      <>
        <div className="relative overflow-x-auto">
          <h3 className="text-xl font-medium mt-5 text-gray-800">
            Invoice {invoiceId}
          </h3>
          <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-3">
            <div className="col-span-1 bg-white shadow-xl rounded-md p-5 text-center">
              <h5 className="mb-1 text-xl font-semibold text-gray-900">
                Patient Information
              </h5>

              <h5 className="mb-1 text-xl font-medium text-gray-900">
                {patient?.name}
              </h5>
              <p className="text-sm text-gray-500">{patient?.email}</p>
              <p className="text-sm text-gray-500">{patient?.contactNumber}</p>
            </div>

            <div className="col-span-1 bg-white shadow-xl rounded-md p-5 text-center">
              <h5 className="mb-1 text-xl font-semibold text-gray-900">
                Contact Address
              </h5>
              <div className="mt-2">
                <OrderLabelValue
                  label="Address"
                  value={contactAddress?.fullAddress}
                />
                <OrderLabelValue
                  label="Suburb"
                  value={contactAddress?.suburb}
                />
                <OrderLabelValue label="City" value={contactAddress?.city} />
                <OrderLabelValue
                  label="PostCode"
                  value={contactAddress?.postCode}
                />
                <OrderLabelValue
                  label="Country"
                  value={contactAddress?.country}
                />
              </div>
            </div>

            <div className="col-span-1 bg-white shadow-xl rounded-md p-5 text-center">
              <h5 className="mb-1 text-xl font-semibold text-gray-900">
                Delivery Address
              </h5>
              <div className="mt-2">
                <OrderLabelValue
                  label="Address"
                  value={deliveryAddress?.fullAddress}
                />
                <OrderLabelValue
                  label="Suburb"
                  value={deliveryAddress?.suburb}
                />
                <OrderLabelValue label="City" value={deliveryAddress?.city} />
                <OrderLabelValue
                  label="PostCode"
                  value={deliveryAddress?.postCode}
                />
                <OrderLabelValue
                  label="Country"
                  value={deliveryAddress?.country}
                />
              </div>
            </div>

            <div className="col-span-1 bg-white shadow-xl rounded-md p-5 text-center">
              <h5 className="mb-1 text-xl font-semibold text-gray-900">
                Payment Information
              </h5>
              <div className="mt-2">
                <OrderLabelValue
                  label="Amount"
                  value={`$ ${payment?.amount}`}
                />
                <OrderLabelValue label="Status" value={payment?.status} />
              </div>
            </div>

            <div className="col-span-1 bg-white shadow-xl rounded-md p-5 text-center">
              <h5 className="mb-1 text-xl font-semibold text-gray-900">
                Pricing Information
              </h5>
              <div className="mt-2">
                <OrderLabelValue
                  label="Products Price"
                  value={`$ ${productsPrice.toFixed(2)}`}
                />
                <OrderLabelValue
                  label="Shipping Price"
                  value={`$ ${shippingPrice.toFixed(2)}`}
                />
                <OrderLabelValue
                  label="Bank Charge Price"
                  value={`$ ${bankChargePrice.toFixed(2)}`}
                />
                <OrderLabelValue
                  label="GST Price"
                  value={`$ ${gstPrice.toFixed(2)}`}
                />
                <OrderLabelValue
                  label="Total Price"
                  value={`$ ${totalPrice.toFixed(2)}`}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 bg-white shadow-xl rounded-md p-5">
            <h5 className="mb-1 text-xl font-semibold text-gray-900">
              Ordered Products
            </h5>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Quantity
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Strength
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr
                    key={product._id || product.id}
                    className="bg-white border-b"
                  >
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {product.name}
                    </th>
                    <td className="px-6 py-4">{product.qty}</td>
                    <td className="px-6 py-4">
                      {product.selectedPackSize.size}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  return (
    <AdminLayout>
      <ListHeader title={`Order Details`} />
      {content}
    </AdminLayout>
  );
};

export default Orderdetails;

const OrderLabelValue = ({ label, value }) => {
  return (
    <div className="flex gap-x-1 items-center mb-1 text-wrap">
      <span className="font-medium">{label}:</span>
      <span className="text-sm text-gray-500">{value}</span>
    </div>
  );
};
