import axios from "../../helper/axios";
import InquiryForm from "./InquiryForm";
import InquiryButton from "./InquiryButton";
import Layout from "../../components/layout";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/context";
import LoginLine from "../../components/shared/LoginLine";
import SelectedPdForInquiry from "./SelectedPdForInquiry";
import InquirySuccess from "./InquirySuccess";

const Inquiry = () => {
  const navigate = useNavigate();
  const { auth, selectedItems } = useGlobalContext();
  const [user, setUser] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errMessage, setErrorMessage] = useState("");

  // When product is empty redirect to home page
  useEffect(() => {
    if (selectedItems.length === 0) {
      navigate("/");
    }
  }, [navigate, selectedItems.length]);

  //get user details when login is successfull
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/user/details/${auth._id}`);
        setUser(data);
      } catch (err) {
        console.log(err);
      }
    };

    if (auth !== null) {
      fetchData();
    }
  }, [auth, auth?._id]);

  // control modal
  const controlModal = () => {
    setIsOpen((prev) => !prev);
  };

  // api call when one or more products are available and inquiry form submitted
  const submitHandler = async (data, reset) => {
    const customer = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
    };
    const inquiryItems = data.selectedItems;
    const customMessage = data.moreInfo;
    const inquiryProducts = selectedItems.map((item) => item._id || item.id);

    try {
      setSubmitting(true);
      setErrorMessage("");
      const { data } = await axios.post(
        "/inquiry",
        {
          customer,
          inquiryItems,
          customMessage,
          inquiryProducts,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (data) {
        reset();
        controlModal();
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <LoginLine />
      <div className="wrapper font-poppins">
        <SelectedPdForInquiry />
        {auth === null && <InquiryButton />}

        {auth !== null && user !== null && (
          <InquiryForm
            defaultValues={user}
            submitHandler={submitHandler}
            submitting={submitting}
            errMessage={errMessage}
          />
        )}
      </div>
      <InquirySuccess isOpen={isOpen} controlModal={controlModal} />
    </Layout>
  );
};

export default Inquiry;
