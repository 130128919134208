import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "../../components/layout";
import { useForm, FormProvider } from "react-hook-form";
import PatientForm from "./PatientForm";
import Decleration from "./Decleration";
import orderSchema from "./orderSchema";
import Products from "./Products";
import LoginLine from "../../components/shared/LoginLine";
import OrderCalculation from "./OrderCalculation";
import {
  getShippingPrice,
  getProductTotalPrice,
  getBankChargePrice,
  getGstPrice,
  orderTotalPrice,
} from "../../utils/orderCal";
import axiosInstance from "../../helper/axios";
import { useGlobalContext } from "../../hooks/context";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { useNavigate } from "react-router-dom";

const Order = () => {
  const { auth } = useGlobalContext();
  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(orderSchema),
  });
  const navigate = useNavigate();

  const { handleSubmit, reset } = methods;

  // Order Calculation
  const productTotalPrice = getProductTotalPrice(products);
  const shippingPrice = getShippingPrice();
  const bankChargePrice = getBankChargePrice();
  const gstPrice = getGstPrice(
    methods.watch().deliveryCountry,
    productTotalPrice,
    shippingPrice
  );

  const totalPrice = orderTotalPrice(
    productTotalPrice,
    shippingPrice,
    bankChargePrice,
    gstPrice
  );

  const updatedProducts = products.map((pd) => {
    const { name, availablePackSize, qty, price, subTotal } = pd || {};
    return {
      name,
      selectedPackSize: availablePackSize.find((av) => av.selected),
      qty,
      price,
      subTotal,
    };
  });

  // Modal open and other data update
  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      contactFullAddress,
      contactSuburb,
      contactCity,
      contactPostCode,
      contactCountry,
      deliveryFullAddress,
      deliverySuburb,
      deliveryCity,
      deliveryPostCode,
      deliveryCountry,
      prescription,
      certifyAndDeclare,
    } = data;

    const patient = {
      name: `${firstName} ${lastName}`,
      email,
      contactNumber: phone,
    };

    const contactAddress = {
      fullAddress: contactFullAddress,
      suburb: contactSuburb,
      city: contactCity,
      postCode: contactPostCode,
      country: contactCountry,
    };

    const deliveryAddress = {
      fullAddress: deliveryFullAddress,
      suburb: deliverySuburb,
      city: deliveryCity,
      postCode: deliveryPostCode,
      country: deliveryCountry,
    };

    const payload = {
      products: updatedProducts,
      patient,
      contactAddress,
      deliveryAddress,
      certifyAndDeclare,
      shippingPrice,
      bankChargePrice,
      gstPrice,
      totalPrice,
    };

    const formData = new FormData();
    formData.append("prescription", prescription[0]);
    formData.append("data", JSON.stringify(payload));

    // Todo: Create order api call
    setMessage("");
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.post(
        "/order-create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (status === 201) {
        reset();
        navigate(`/orders/${data.order._id}`);
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response?.data?.message);
      } else {
        setMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <LoginLine />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="margin-top-3 font-poppins wrapper">
            <h1 className="text-[14px] font-bold text-black text-center uppercase">
              Order Placement
            </h1>
          </div>
          <PatientForm />

          <Products products={products} setProducts={setProducts} />

          <OrderCalculation
            shippingPrice={shippingPrice}
            bankChargePrice={bankChargePrice}
            gstPrice={gstPrice}
            totalPrice={totalPrice}
          />

          <Decleration />
          <div className="font-poppins wrapper margin-top-3">
            <button
              type="submit"
              className="mb-2 block bg-secondary-50 text-white w-full py-2 rounded "
              disabled={loading}
            >
              {loading ? "Loading..." : "Confirm Order"}
            </button>
            <ErrorMessage message={message} />
          </div>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default Order;

//   <OrderSuccess orderInfo={orderInfo} />TODO:success hoile aita
