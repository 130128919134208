/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
//import productss from "../../data/productss";
import RightArrow from "../../icon/RightArrow";
import ProductCard from "../../components/ui/ProductCard";
import axiosInstance from "../../helper/axios";

const NewProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get("/products?type=new");
        setProducts(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, []);

  //const newProducts = products.filter((product) => product.type === "New");

  return (
    <div className="margin-top-3 font-poppins">
      <h5 className="wrapper text-[10px] font-bold text-black">
        New Products:
      </h5>
      <div className="relative mt-1.5 bg-secondary-50 px-[1.7rem] py-2">
        <div className="md:wrapper">
          <Swiper
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
              renderBullet: function () {
                return `<span class=""></span>`;
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {products?.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductCard product={product} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="flex justify-end mr-5 mt-1">
        <Link
          to="/products/all"
          className="text-[10px] font-medium flex items-center"
        >
          More...
          <RightArrow className="w-4 h-4 text-primary-200" />
        </Link>
      </div>
    </div>
  );
};

export default NewProducts;
