import React, { useState } from "react";
import AdminLayout from "../../../components/layout/AdminLayout";
import ListHeader from "../../../components/shared/ListHeader";
import ProductForm from "../../../components/product/ProductForm";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../hooks/context";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import getErrorMsg from "../../../utils/getErrorMsg";
import axiosInstance from "../../../helper/axios";
import { toast } from "react-toastify";

export default function ProductCreate() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { auth } = useGlobalContext();

  const submit = async (formData) => {
    const data = {
      ...formData,
      category: formData.category.value,
      types: formData.types.map((type) => type.value),
      formulation: formData.formulation.value,
    };

    setIsLoading(true);
    setError(null);
    try {
      const res = await axiosInstance.post("/product-create", data, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (res.status === 201) {
        toast.success(res.data.message);
        navigate(`/admin/products`);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const defaultValues = {
    name: "",
    category: { label: "", value: "" },
    types: [],
    generic: "",
    orginator: "",
    formulation: {
      label: "",
      value: "",
    },
    availableStrength: "",
    application: "",
    dosage: "",
    pdDescription: "",
    indications: "",
    sideEffect: "",
    prescribingInformation: "",
    faqs: "",
    availablePackSize: [
      {
        size: "",
        packSize: "",
        packName: "",
        price: 0,
        packImage: null,
      },
    ],
  };

  return (
    <AdminLayout>
      <ListHeader title="Product Create" />
      <div className="py-5">
        <ProductForm
          defaultValues={defaultValues}
          btnText="Create"
          submit={submit}
          isLoading={isLoading}
        />
        {!!error && <ErrorMessage message={getErrorMsg(error)} />}
      </div>
    </AdminLayout>
  );
}
