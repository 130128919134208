import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../hooks/context";

const SelectedPdForInquiry = () => {
  const { selectedItems, deleteSelectedItem } = useGlobalContext();

  const deleteSelectedItemHandler = (id) => {
    deleteSelectedItem(id);
  };

  return (
    selectedItems?.length > 0 && (
      <div className=" ">
        {selectedItems?.map((item, i) => (
          <div
            key={i}
            className="margin-top-3 px-3 py-2 text-primary-50 border-red-300 border rounded bg-secondary-50 font-medium text-sm grid grid-cols-12 "
          >
            <p className="text-sm font-semibold col-span-4">
              {i + 1}. Brand Name:
              <span className=" font-medium"> {item.name}</span>
            </p>
            <p className="text-sm font-semibold col-span-4">
              Generic: <span className="font-medium"> {item.generic}</span>
            </p>
            <p className="text-sm font-semibold col-span-3 ">
              Orginator: <span className=" font-medium"> {item.orginator}</span>
            </p>
            <span
              className="text-md text-red-500 text-center cursor-pointer font-bold col-span-1"
              onClick={() => deleteSelectedItemHandler(item.id)}
            >
              X
            </span>
          </div>
        ))}

        <Link
          to="/"
          className="margin-top-3 px-3 py-2 bg-primary-50 border-red-300 border rounded text-gray-600 font-medium text-sm flex justify-center gap-x-1"
        >
          <span className="font-bold text-md">+</span>
          Add More Product for Inquiry
        </Link>
      </div>
    )
  );
};

export default SelectedPdForInquiry;
