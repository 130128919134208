import React from "react";
import Layout from "../layout";

const TermnCon = () => {
  return (
    <Layout>
      <div className="p-4 xl:p-20">
        <h3 className="text-2xl font-bold mb-4">Terms and Conditions</h3> <br />
        <h3 className="text-md font-medium mb-8">
          Hope Pharma Connections Limited is a New Zealand based humanitarian
          business to support cancer patients and other patients with non-funded
          medicines need by sourcing quality generic medicines from
          manufacturers worldwide. We promote inexpensive cancer medicines so
          that every patient can get the medication they need without depending
          on others or without compromising their financial stability. We
          operate in New Zealand, Australia and within Pacific Islands. We are
          also able to support patients around the world provided regulations in
          the specific countries permit.
        </h3>{" "}
        <h3 className="text-md font-normal mb-4">
          We're supplying Oncology, Anti-viral, Biotech, Liver diseases
          (Hepatitis B, C) and other lifesaving prescription drugs. We follow
          international drugs rules and regulations to supply prescription
          medicines globally. According to WTO rules and regulations, we can
          supply our prescription medicine in most of the country for patient’s
          personal use. We assist to supply required medicine against valid
          prescription and other documents as required by the laws of the
          jurisdiction in which customer or patient are present. We consult and
          facilitate patients, hospitals, clinicians, and researchers in
          accessing products through reliable sources, which aren't available or
          approved in their home country.
        </h3>
        <h3 className="text-md font-normal mb-4">
          We also make extra effort to take emergency orders and provide urgent
          fulfilment. Many clients of ours are happy with our services and we
          hope you will feel the same.
        </h3>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">1. Our Terms </h3>
          <h3 className="text-md font-normal mb-4">
            If you are accessing our website, you are agreeing to our terms and
            conditions that we set up to those who access our website. We expect
            you to follow these instructions as well as all applicable laws and
            regulations and take responsibility for compliance with any
            applicable local laws. If for any reason you feel like not agreeing
            to our terms and conditions, we would recommend you not to access
            our website. We are very serious about this matter as all our
            materials within this web site are protected by applicable copyright
            and trademark law. And we strictly prohibit any temperament with
            them.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">2. License </h3>
          <h3 className="text-md font-normal mb-4">
            2.1. As per license terms we do grant the permission to temporarily
            download one copy of the materials (such as information, pictures,
            prices etc.) on our website for personal, non-commercial view only.
            We would like to add that this is not a grant of a license, we are
            not transferring our title and under the license we are providing
            you, these actions described below cannot be committed: Any sort of
            modification or copying of our materials. Using our materials for
            any commercial purpose or publishing it anywhere. Any sort of
            attempt to temper with our website, its source code. Removing
            copyright from any of the materials which was downloaded from our
            website (such as watermark). Transferring our website materials
            after downloading it to other persons. <br /> 2.2. The license that
            we are providing will automatically terminated if you have been
            caught violating any of the license terms. We strictly ask you to
            destroy any downloaded materials in your possession regardless of
            its form whether electronic or printed format.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">3. Disclaimer </h3>
          <h3 className="text-md font-normal mb-4">
            As a disclaimer we would like to declare that we do not make any
            promises of our products success rate. We are merely the reseller
            not the producer. As of that we do not take any warranty claim if
            the product purchased from our website not being able to provide
            successful results to you. The content of this site isn't intended
            to be a substitute for professional medical advice, diagnosis, or
            treatment. Information provided on this website is for reference
            only. The content on this website has been supplied to Hope Pharma
            Connections Limited by independent third parties contracted to
            provide information for our website. Hope Pharma Connections Limited
            relies on these third parties to create and maintain this
            information and cannot guarantee the medical efficacy, accuracy or
            reliability of the information that has been provided to us. If you
            need any advice or information about the drugs on this website, a
            medical condition or treatment advice, you ought to always speak to
            a health care provider. Only healthcare professional can provide you
            advice on what is safe and most effective for you. Please note that
            Hope Pharma Connections Limited is an online based medicine
            supplying service focused on providing safe and affordable
            lifesaving prescription drugs to thousands of customers globally. HP
            will assist you in getting required medicine against valid
            prescription and other documents as required by the laws of the
            jurisdiction in which you are present. We consult and facilitate
            patients, hospitals, clinicians and researchers in accessing
            products through reliable sources, which aren't available or
            approved in their home country. All trademarks and registered
            trademarks appearing on this website are the property of their
            respective owners and Hope Pharma Connections Limited is not
            affiliated with them in any way. Any advice on the use of drugs on
            this website is for reference only and is not a substitute for
            medical advice. Please consult with healthcare professional for
            medical advice.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">4. Our limitations</h3>
          <h3 className="text-md font-normal mb-4">
            We would like you to read through our limitations as we certainly
            have some. We would expect you to purchase every product that you
            wish to purchase from our website at your own accord. As we will
            never be hold accountable for our products not being effective to
            you. In no event will our website or our suppliers will be liable
            for any damage caused to you by purchasing our products.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">
            5. Revision and error data
          </h3>
          <h3 className="text-md font-normal mb-4">
            All the materials that appear on our website can have technical
            typographical as well as photographic errors. You must know that we
            do not warrant any of the materials on the website are accurate,
            current or complete. As the information are provided by researching
            from internet or from the independent third parties but not us. We
            can make changes to our website as well as its content as per we see
            fit without giving any sort of notice. We also do not make any
            commitment towards updating the materials.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">6. About</h3>
          <h3 className="text-md font-normal mb-4">
            links Any external link provided in our website whether it be video
            link for our products or others we do not take responsibility for
            the contents these linked site has as we do not have any control
            over them. Inclusion of any such link does not imply endorsement by
            our website. We ask the users to visit these external links at their
            own risk.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">
            7. Modification of our terms
          </h3>
          <h3 className="text-md font-normal mb-4">
            We may revise and modify any terms of use at any time without any
            notice. By accessing our website, you are agreeing to the current
            version of terms and conditions that are on use.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">8. Payment</h3>
          <h3 className="text-md font-normal mb-4">
            Payment has to be made as per our policy be it through online or
            manually transferring to our designated bank account. We take
            payment in advance using your chosen method of payment and this
            policy cannot be changed as we don’t have provision for post payment
            or credit. As a customer we appreciate you know that before ordering
            any product.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">9. Government Law</h3>
          <h3 className="text-md font-normal mb-4">
            Any claim towards our website will be governed by the laws of the
            Country we operate in. Tax Customers are responsible for all the
            applicable taxes in their respective countries.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">10. Refund Policy</h3>
          <h3 className="text-md font-normal mb-4">
            As we’re supplying lifesaving medicines against valid prescription
            and other documents as required by the laws of the jurisdiction in
            which you are present, so it’s not like other general kind
            e-commerce website orders. Here has lots of limitations and
            regulations that we’ve to abide by. As such, we can’t give any
            return or refund facility after placing the order. We recommend
            confirming all information before placing the order. We’ll try to
            support our best to solve any issue regarding the order.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">
            11. Typographical errors
          </h3>
          <h3 className="text-md font-normal mb-4">
            If it ever occurs that any product is priced incorrectly or with
            incorrect information due to typographical error or pricing error or
            error in product information received from our suppliers, our
            website shall have the right to cancel or refuse any order placed on
            a particular product listed at our website. If in any case a
            situation occurs when you already sent us payment for purchasing a
            particular product before we notice that the product had some
            typographical error, we will return the payment as soon as possible.
            That being said all the general terms and conditions are applicable
            to use of a website.
          </h3>
        </div>
        <h3 className="text-sm font-light italic mt-10">
          If you are confused with any of our terms and conditions and seek to
          contact us, feel free to contact us at our email:{" "}
          <span className="text-sm font-semibold">
            info@hopepharmaconnections.co.nz
          </span>
        </h3>
      </div>
    </Layout>
  );
};

export default TermnCon;
