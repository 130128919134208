import React from "react";

const OrderCalculation = ({
  shippingPrice,
  bankChargePrice,
  gstPrice,
  totalPrice,
}) => {
  return (
    <div className="margin-top-3 font-poppins wrapper">
      <div className="mt-3 py-3 px-4 bg-primary-50 rounded text-black">
        <div className=" grid grid-cols-8 items-center justify-between">
          <p className="text-xs font-bold col-span-6">Shipping Cost</p>
          <p className="text-xs font-semibold col-span-2">
            NZD$ {shippingPrice.toFixed(2)}
          </p>
        </div>

        <div className="mt-2 grid grid-cols-8 items-center justify-between col">
          <p className="text-xs font-bold col-span-6">
            Import Docs & Processing Fee
          </p>
          <p className="text-xs font-semibold col-span-2">
            NZD$ {bankChargePrice.toFixed(2)}
          </p>
        </div>
        {gstPrice > 0 && (
          <div className="mt-2 grid grid-cols-8 items-center justify-between">
            <p className="text-xs font-bold col-span-6">GST (15%)</p>
            <p className="text-xs font-semibold col-span-2">
              NZD$ {gstPrice.toFixed(2)}
            </p>
          </div>
        )}
      </div>
      <div className="mt-3 py-3 px-4 bg-secondary-50 rounded text-white">
        <div className="grid grid-cols-8 items-center justify-between">
          <p className="text-xs font-bold col-span-6">Total Price</p>
          <p className="text-xs font-semibold col-span-2">
            NZD ${totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderCalculation;
