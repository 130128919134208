import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack, BiPlus } from "react-icons/bi";

const ListHeader = ({ title, isCreate, path }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between py-3 border-b">
      <h3 className="text-2xl font-semibold text-gray-800">{title}</h3>
      {isCreate ? (
        <Link
          to={path}
          className="flex items-center gap-x-0.5 bg-black text-white py-1.5 rounded px-2"
        >
          <BiPlus />
          Create
        </Link>
      ) : (
        <button
          className="flex items-center gap-x-0.5 bg-black text-white py-1.5 rounded px-2"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack />
          Back
        </button>
      )}
    </div>
  );
};

export default ListHeader;
