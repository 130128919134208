/* eslint-disable no-lone-blocks */

import ErrorMessage from "../../ui/ErrorMessage";

export default function AdminSelect({
  id,
  name,
  label,
  option,
  options,
  onChange,
  error,
  ...rest
}) {
  const handleChange = (e) => {
    const targetOption = options.find(
      (option) => option.value === e.target.value
    );

    onChange(!!targetOption ? targetOption : null);
  };

  return (
    <div className="col-span-1">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-semibold text-gray-900"
        >
          {label}
        </label>
      )}

      <select
        id={id}
        name={name}
        onChange={handleChange}
        className="border border-gray-300 text-sm rounded focus:ring-black-500 focus:border-black-500 block w-full px-2.5 py-[0.70rem]"
        value={option?.value || ""}
        {...rest}
      >
        <option value="">Select {name}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {!!error && <ErrorMessage message={error?.message} />}
    </div>
  );
}

// Usage example
{
  /* <AdminSelect
  id={name}
  name={name}
  label="Type"
  option={option}
  onChange={onChange}
  options={[
    { label: "New", value: "new" },
    { label: "Popular", value: "popular" },
    { label: "Generic", value: "generic" },
  ]}
/>; */
}
