import * as yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

// form validation schema
const orderSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
    phone: yup.number().min(5).required("Contact number is required"),
    contactFullAddress: yup.string().required("Full address is required"),
    contactSuburb: yup.string().required("Suburb is required"),
    contactCity: yup.string().required("City is required"),
    contactPostCode: yup
      .number()
      .typeError("Post code must be a number")
      .min(4)
      .required("Post code is required"),
    contactCountry: yup.string().required("Country is required"),
    deliveryFullAddress: yup.string().required("Full address is required"),
    deliverySuburb: yup.string().required("Suburb is required"),
    deliveryCity: yup.string().required("City is required"),
    deliveryPostCode: yup
      .number()
      .typeError("Post code must be a number")
      .min(4)
      .required("Post code is required"),
    deliveryCountry: yup.string().required("Country is required"),
    certifyAndDeclare: yup.bool().oneOf([true], "Field must be checked"),
  })
  .shape({
    prescription: yup
      .mixed()
      .test("required", "Prescription is required", (file) => {
        if (file?.length > 0) return true;
        return false;
      })
      .test("fileSize", "Prescription file is too large", (file) => {
        return file && file[0]?.size <= 1024 * 1024 * 2;
      })
      .test("fileFormat", "Unsupported Format", (file) => {
        return file?.length > 0 && SUPPORTED_FORMATS.includes(file[0]?.type);
      }),
  });

export default orderSchema;
