export const getProductTotalPrice = (products = []) => {
  return products.reduce((prev, curr) => {
    return prev + curr.subTotal;
  }, 0);
};

export const getShippingPrice = () => {
  return 157.0;
  // if (!country) {
  //   return 0;
  // }
  // if (country === process.env.DEFAULT_COUNTRY || country === "New Zealand") {
  //   return 20;
  // } else {
  //   return 90;
  // }
};

export const getBankChargePrice = () => {
  return 39.5;
  // if (!country) {
  //   return 0;
  // }
  // if (country === process.env.DEFAULT_COUNTRY || country === "New Zealand") {
  //   return 25;
  // } else {
  //   return 0;
  // }
};

export const getGstPrice = (country, productTotalPrice, shippingPrice) => {
  if (country === "New Zealand") {
    return ((productTotalPrice + shippingPrice) * 15) / 100;
  } else {
    return 0;
  }
};

export const orderTotalPrice = (...args) => {
  let total = args.reduce((prev, curr) => {
    return prev + curr;
  }, 0);

  total = Number(total.toFixed(2));

  return total;
};
