import React, { useRef, useState } from "react";
import ErrorMessage from "../ui/ErrorMessage";
import axiosInstance from "../../helper/axios";
import { useGlobalContext } from "../../hooks/context";
import { toast } from "react-toastify";

const uploadFile = async (file, token) => {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axiosInstance.post("/products/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

const ProductImage = ({ callback, defaultImageUrl, error }) => {
  const fileRef = useRef();
  const { auth } = useGlobalContext();
  const [imageUrl, setImageUrl] = useState(defaultImageUrl);

  const handleImageUpload = () => {
    fileRef.current.addEventListener("change", updateImageDisplay);
    fileRef.current.click();
  };

  const updateImageDisplay = async () => {
    const file = fileRef.current.files[0];
    const previewUrl = URL.createObjectURL(file);
    setImageUrl(previewUrl);

    try {
      const res = await uploadFile(file, auth.token);

      setImageUrl(res.data);
      callback(res.data);
    } catch (error) {
      toast.error("Image upload failed!");
      setImageUrl(defaultImageUrl || "");
    }
  };

  return (
    <>
      <input id="file" type="file" hidden ref={fileRef} />
      <button
        type="button"
        className="grid place-items-center px-2 py-2 rounded text-white bg-slate-700 hover:bg-slate-700/80"
        onClick={handleImageUpload}
      >
        Upload
      </button>

      {imageUrl && (
        <img src={imageUrl} alt="" className="rounded w-[60px] h-[40px]" />
      )}
      {!!error && <ErrorMessage message={error?.message} />}
    </>
  );
};

export default ProductImage;
