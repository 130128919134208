import React from "react";
import Layout from "../layout";

const AboutUs = () => {
  return (
    <Layout>
      <div className="p-4 xl:p-20">
        <h3 className="text-2xl font-semibold mb-8">About Us</h3>{" "}
        <h3>
          {" "}
          Welcome to Hope Pharma Connections Limited, we understand the
          significant impact that cancer can have on individuals and their loved
          ones and recognize that access to life-saving treatments should not be
          limited by financial resources or location.
        </h3>{" "}
        <br />
        <h3>
          Hope Pharma Connections Limited is a renowned and distinguished
          company that has been providing generic cancer medicines to cancer
          patients since August 2020. The company has set its mission to serve
          the cancer patients and their families by sourcing affordable yet
          high-quality generic medicines. The company operates as a humanitarian
          business, prioritizing the welfare of the patients above all else.
        </h3>{" "}
        <br />
        <h3>
          Hope Pharma Connections Limited has established itself as a reliable
          supplier of generic cancer medicines, with a focus on affordability
          and quality. The company is committed to ensuring that cancer patients
          have access to the medicines they need, regardless of their financial
          circumstances. The company has a team of highly skilled professionals
          who work tirelessly to source the best quality generic medicines from
          reputable manufacturers around the world.
        </h3>{" "}
        <br />
        <h3>
          The company's dedication to its mission has earned it a reputation as
          a compassionate and ethical provider of cancer medicines. The company
          understands the challenges that cancer patients face and strives to
          provide them with the best possible care and support. The company
          works closely with oncologists, pharmacists, and other healthcare
          professionals to ensure that patients receive the most appropriate
          treatments for their specific condition.
        </h3>{" "}
        <br />
        <h3>
          Hope Pharma Connections Limited is driven by a vision of a world where
          cancer patients have access to affordable, high-quality medicines,
          regardless of where they live or their financial circumstances. The
          company is committed to making this vision a reality by working
          tirelessly to source the best possible medicines at the lowest
          possible prices. With a strong focus on patient care and a commitment
          to ethical business practices, Hope Pharma Connections Limited is a
          company that is making a real difference in the lives of cancer
          patients and their families.
        </h3>
      </div>
    </Layout>
  );
};

export default AboutUs;
