import { AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
import { BsFillCartFill } from "react-icons/bs";

const AdminNavbar = ({ show, onToggle }) => {
  return (
    <div
      className={`px-4 py-4 mx-auto flex justify-between items-center border-b-2 transition-all duration-300 xl:ml-[15rem] ${
        show ? "ml-[15rem]" : "ml-[0]"
      }`}
    >
      <span onClick={() => onToggle(true)} className="cursor-pointer xl:hidden">
        <AiOutlineMenu size={30} />
      </span>

      <div className="py-5"></div>

      {/* <div className="bg-gray-200 rounded-full flex items-center px-2 w-[200px] sm:w-[400px]">
        <AiOutlineSearch size={25} />
        <input
          className="bg-transparent p-2 w-full focus:outline-none"
          type="text"
          placeholder="Search foods"
        />
      </div>
      <button className="bg-black text-white md:flex items-center py-2 rounded-full border border-black px-5 ">
        <BsFillCartFill size={20} className="md:mr-2" />
        <span className="hidden md:block">Cart</span>
      </button> */}
    </div>
  );
};

export default AdminNavbar;
