import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { sidebarData } from "../../data/sidebar";
import { BiLogIn } from "react-icons/bi";
import { useGlobalContext } from "../../hooks/context";

const AdminSidebar = ({ show, onToggle }) => {
  const { logOut } = useGlobalContext();
  return (
    <>
      {show && (
        <div
          className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"
          onClick={() => onToggle(false)}
        ></div>
      )}

      <div
        className={`fixed bg-[#0A2558]  top-0 left-0 w-[15rem] h-screen z-10 transition-transform duration-300 xl:translate-x-0 ${
          show ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 flex justify-between items-center">
          <Link to="/admin/dashboard">
            <img
              src="/logo/logo.png"
              alt="Home Pharma"
              className="w-[8rem] h-[4rem] invert"
            />
          </Link>

          <span
            className="cursor-pointer block text-white xl:hidden"
            onClick={() => onToggle(false)}
          >
            <AiOutlineClose size={30} />
          </span>
        </div>
        <div className="sidebar-scrollbar">
          <nav>
            <ul className="space-y-1">
              {sidebarData.map((route) => (
                <SidebarItem key={route.text} {...route} />
              ))}
              <li>
                <button
                  type="button"
                  className="flex w-full items-center font-medium tracking-wide capitalize text-sm px-2 py-3 transition rounded text-white hover:bg-[#081D45]"
                  onClick={() => logOut()}
                >
                  <BiLogIn size={22} />
                  <span className="ml-2">Logout</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

const SidebarItem = ({ text, path, Icon }) => {
  return (
    <li>
      <NavLink
        to={path}
        className={({ isActive }) =>
          `flex items-center font-medium tracking-wide capitalize text-sm px-2 py-3 transition rounded text-white hover:bg-[#081D45] ${
            isActive ? "bg-[#081D45]" : ""
          }`
        }
      >
        <Icon size={22} />
        <span className="ml-2">{text}</span>
      </NavLink>
    </li>
  );
};

export default AdminSidebar;
