import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axiosInstance from "../../helper/axios";
import ErrorMessage from "../ui/ErrorMessage";
import { useGlobalContext } from "../../hooks/context";

const initialOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "NZD",
  intent: "capture",
};

function PaypalPayment({ ourOrderId, totalPrice, onPaymentUpdate }) {
  const { auth } = useGlobalContext();
  const [message, setMessage] = useState("");

  const handlePaypalCreateOrder = async () => {
    try {
      setMessage("");

      const response = await axiosInstance.post(
        "/paypal/create-order",
        {
          totalPrice: totalPrice,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      // TODO: Paypal order created successfully
      if (response.status === 201 && response.data?.id) {
        return response.data.id;
      } else {
        const errorDetail = response.data.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${response.data.debug_id})`
          : JSON.stringify(response.data);

        throw new Error(errorMessage);
      }
    } catch (error) {
      setMessage(error.response.data.message || "Failed to create order!");
      return null;
    }
  };

  ///paypal/:orderId/capture
  const handlePaypalApprove = async (data) => {
    try {
      setMessage("");

      const response = await axiosInstance.post(
        `/paypal/${data.orderID}/capture`,
        {
          orderId: ourOrderId,
          totalPrice: totalPrice,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      if (response.status === 200) {
        // TODO: Order create

        onPaymentUpdate();
      } else {
        setMessage(`Could not approve...`);
      }
    } catch (error) {
      setMessage(error.response.data.message || "Failed to create order!");
    }
  };

  return (
    <div className="wrapper">
      <PayPalScriptProvider options={initialOptions}>
        <div className="py-4 relative flex flex-col">
          <PayPalButtons
            style={{
              shape: "rect",
              layout: "vertical",
            }}
            createOrder={handlePaypalCreateOrder}
            onApprove={handlePaypalApprove}
          />
        </div>
      </PayPalScriptProvider>
      <ErrorMessage message={message} />
    </div>
  );
}

export default PaypalPayment;
