import React from "react";
import Layout from "../layout";

const Disclaimer = () => {
  return (
    <Layout>
      <div className="p-4 xl:p-20">
        <div>
          <h3 className="text-2xl  font-bold mt-5 mb-10"> Disclaimer </h3>
          <h3 className="text-md font-normal mb-4">
            As a disclaimer we would like to declare that we do not make any
            promises of our products success rate. We are merely the reseller
            not the producer. As of that we do not take any warranty claim if
            the product purchased from our website not being able to provide
            successful results to you. The content of this site isn't intended
            to be a substitute for professional medical advice, diagnosis, or
            treatment. Information provided on this website is for reference
            only. The content on this website has been supplied to Hope Pharma
            Connections Limited by independent third parties contracted to
            provide information for our website. Hope Pharma Connections Limited
            relies on these third parties to create and maintain this
            information and cannot guarantee the medical efficacy, accuracy or
            reliability of the information that has been provided to us. If you
            need any advice or information about the drugs on this website, a
            medical condition or treatment advice, you ought to always speak to
            a health care provider. Only healthcare professional can provide you
            advice on what is safe and most effective for you. Please note that
            Hope Pharma Connections Limited is an online based medicine
            supplying service focused on providing safe and affordable
            lifesaving prescription drugs to thousands of customers globally.
            MFW will assist you in getting required medicine against valid
            prescription and other documents as required by the laws of the
            jurisdiction in which you are present. We consult and facilitate
            patients, hospitals, clinicians and researchers in accessing
            products through reliable sources, which aren't available or
            approved in their home country. All trademarks and registered
            trademarks appearing on this website are the property of their
            respective owners and Hope Pharma Connections Limited is not
            affiliated with them in any way. Any advice on the use of drugs on
            this website is for reference only and is not a substitute for
            medical advice. Please consult with healthcare professional for
            medical advice.
          </h3>
        </div>
        <div>
          <h3 className="text-xl font-bold mt-10 mb-1">Privacy Policy</h3>
          <h3 className="text-md mt-6 font-normal mb-4">
            At Hope Pharma Connections Limited, we prioritize the privacy and
            security of our customers' personal information. We collect details
            such as personal identification, medical prescriptions, and payment
            information to process orders, provide support, and improve our
            services. We share this data only with trusted third-party service
            providers and comply with legal requirements, using secure servers
            and encryption to protect it. You have rights to access, correct,
            and request deletion of your data, which you can exercise by
            contacting us.
          </h3>
        </div>
        <h3 className="text-sm font-light italic mt-10">
          We use cookies to enhance your browsing experience and may update this
          policy periodically, notifying you of significant changes. We are not
          responsible for the privacy practices of third-party websites linked
          on our site. For any questions or concerns about our privacy
          practices, please contact us at{" "}
          <span className="text-sm font-semibold">
            <a
              href="mailto:info@hopepharmaconnections.co.nz"
              className="text-blue-400"
            >
              info@hopepharmaconnections.co.nz
            </a>
          </span>{" "}
          or call{" "}
          <span>
            <a href="tel:+6496664673" className="text-gray-700 font-semibold">
              +64-9-666-HOPE(4673)
            </a>
          </span>
        </h3>
      </div>
    </Layout>
  );
};

export default Disclaimer;
