import { useState } from "react";
const locAuth = JSON.parse(localStorage.getItem("auth")) || null;

const useData = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [auth, setAuth] = useState(locAuth);
  const [searchValue, setSearchValue] = useState("");

  const addSelectedItem = (payload) => {
    if (payload.name) {
      const isSelected = selectedItems.some(
        (item) => item.name === payload.name
      );
      if (isSelected) {
        const updatedItems = selectedItems.map((item) =>
          item.name === payload.name ? { ...item, ...payload } : item
        );
        setSelectedItems(updatedItems);
      } else {
        setSelectedItems([...selectedItems, payload]);
      }
    }
  };

  const findSelectedItem = (id) => {
    const item = selectedItems.find((item) => item.id === id);
    return item || {};
  };

  const deleteSelectedItem = (id) => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updatedItems);
  };

  const emptySelectedItems = () => {
    setSelectedItems([]);
  };

  const authUpdate = (user) => {
    localStorage.setItem("auth", JSON.stringify(user));
    setAuth(user);
  };

  const logOut = () => {
    localStorage.removeItem("auth");
    setAuth(null);
  };

  const handleSeacrh = (value) => {
    setSearchValue(value);
  };

  return {
    auth,
    selectedItems,
    addSelectedItem,
    findSelectedItem,
    deleteSelectedItem,
    emptySelectedItems,
    authUpdate,
    logOut,
    searchValue,
    handleSeacrh,
  };
};

export default useData;
