import * as yup from "yup";

export const productSchema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required!"),
    category: yup.object({
      label: yup.string().required("Category is required!"),
      value: yup.string().required("Category is required!"),
    }),
    types: yup
      .array()
      .of(
        yup
          .object({
            label: yup.string().required("Type is required!"),
            value: yup.string().required("Type is required!"),
          })
          .shape({})
      )
      .min(1, "Types is required!"),
    generic: yup.string().required("Generic is required!"),
    orginator: yup.string().required("Orginator is required!"),
    formulation: yup.object({
      label: yup.string().required("Formulation is required!"),
      value: yup.string().required("Formulation is required!"),
    }),
    availableStrength: yup.string().required("Available strength is required!"),
    application: yup.string().required("Application is required!"),
    dosage: yup.string().required("Dosage is required!"),
    pdDescription: yup.string().required("Description is required!"),
    indications: yup.string().required("Indications is required!"),
    sideEffect: yup.string().required("SideEffect is required!"),
    prescribingInformation: yup.string().notRequired(),
    faqs: yup.string().notRequired(),
    availablePackSize: yup
      .array()
      .of(
        yup.object().shape({
          size: yup.string().required("Strength is required!"),
          packSize: yup.string().required("Pack size is required!"),
          packName: yup.string().notRequired(),
          price: yup
            .number()
            .required("Price is required!")
            .positive("Price must be a number!")
            .typeError("Price must be a number!"),
          packImage: yup.string().required("Image is required!"),
        })
      )
      .min(1, "At least 1 item must be provided")
      .required("Required"),
  })
  .required();
