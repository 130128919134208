import { useEffect, useState } from "react";
import AdminLayout from "../../../components/layout/AdminLayout";
import ListHeader from "../../../components/shared/ListHeader";
import axiosInstance from "../../../helper/axios";
import ProductItem from "../../../components/product/ProductItem";
import getErrorMsg from "../../../utils/getErrorMsg";
import ErrorMessage from "../../../components/ui/ErrorMessage";

const ProductList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get("/products");
        setProducts(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, []);

  const onUpdateAfterDelete = (id) => {
    const updatedProducts = products.filter((p) => p._id !== id);
    setProducts(updatedProducts);
  };

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else {
    content = (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Category
            </th>
            <th scope="col" className="px-6 py-3">
              Type
            </th>
            <th scope="col" className="px-6 py-3">
              Generic
            </th>
            <th scope="col" className="px-6 py-3">
              Orginator
            </th>
            <th scope="col" className="px-6 py-3">
              Formulation
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => (
            <ProductItem
              key={product._id}
              product={product}
              onUpdateAfterDelete={onUpdateAfterDelete}
            />
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <AdminLayout>
      <ListHeader
        title="Products"
        isCreate={true}
        path="/admin/products/create"
      />

      <div className="relative overflow-x-auto">{content}</div>
    </AdminLayout>
  );
};

export default ProductList;
