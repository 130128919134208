export const adminProductCategoryOptions = [
  { value: "anti androgen", label: "Anti Androgen" },
  { value: "anti cancer", label: "Anti Cancer" },
  { value: "anti diabetic", label: "Anti Diabetic" },
  { value: "anti viral", label: "Anti Viral" },
  { value: "anti diarrheal", label: "Anti Diarrheal" },
  { value: "anti rheumatic", label: "Anti Rheumatic" },
  { value: "fever", label: "Fever" },
  { value: "oncology", label: "Oncology" },
];

export const adminProductTypeOptions = [
  { label: "New", value: "new" },
  { label: "Popular", value: "popular" },
];

export const adminProductFormulationOptions = [
  { label: "Tablet", value: "tablet" },
  { label: "Capsule", value: "capsule" },
  { label: "Liquid", value: "liquid" },
  { label: "IV", value: "iv" },
];
