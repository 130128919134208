import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "../../components/layout";
import { useState } from "react";
import InquiyInput from "../../components/ui/InquiyInput";
import ErrorMessage from "../../components/ui/ErrorMessage";
import SuccessMessage from "../../components/ui/SuccessMessage";
import axios from "../../helper/axios";
import { useParams } from "react-router-dom";

const schema = yup.object({
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords does't match")
    .required("Confirm password is required"),
});

const ResetPassword = () => {
  const { userId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { newPassword, confirmPassword } = errors;

  const onSubmit = async (data) => {
    const payload = {
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      userId: userId,
    };

    try {
      setSubmitting(true);
      const { data, status } = await axios.post(
        "/auth/reset-password",
        payload
      );

      if (status === 404 || status === 401) {
        setErrorMessage(data.message);
      }
      if (status === 200) {
        reset();
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="margin-top-3 wrapper font-poppins">
        <div className="h-[50vh] flex items-center justify-center ">
          <form className="mt-3 w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-y-2">
              <InquiyInput
                name="newPassword"
                type="password"
                placeholder="Enter new password"
                register={register}
                message={newPassword?.message}
                eyeIconShow={true}
              />
              <InquiyInput
                name="confirmPassword"
                type="password"
                placeholder="Enter confirm password"
                register={register}
                message={confirmPassword?.message}
                eyeIconShow={true}
              />
            </div>
            <button
              type="submit"
              className="mt-3 text-secondary-50 hover:bg-secondary-50 border-secondary-50 hover:text-white rounded-[20px] border font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
              disabled={submitting}
            >
              {submitting ? "Loading ..." : "Reset Password"}
            </button>

            <div className="text-right mt-3">
              <ErrorMessage message={errMessage} />
            </div>
            {success && (
              <SuccessMessage message="Password reset successfully" />
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
