import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import Rating from "../../components/ui/Rating";

const DetailsBanner = ({ product }) => {
  const {
    name,
    category,
    rating = 0,
    totalReview = 0,
    availablePackSize,
  } = product || {};

  const images = availablePackSize.map(
    (availablePack) => availablePack.packImage
  );

  return (
    <div className="margin-top-3 font-poppins overflow-x-hidden">
      <div className="wrapper flex justify-between items-center">
        <h5 className="text-[10px] font-bold text-black">{name}</h5>
        <p className="text-[8px] font-bold text-black">{category}</p>
      </div>
      <div className="relative mt-1.5 bg-secondary-50 px-[1.7rem] py-2">
        <div className="md:wrapper grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1">
            <Swiper
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                  return `<div class=${className}><img
                  src=${images[index]}
                  alt=""
                /></div>`;
                },
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="w-full bg-white">
                    <div className="pt-7 w-full h-[20rem]">
                      <img src={image} alt={name} className="w-full h-full" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="col-span-1 pt-16 pb-2 bg-white">
            <div className="text-right px-5">
              <p className="text-[8px] font-medium">({totalReview})</p>
              <p className="text-[8px] font-medium">Reviews</p>
              <div className="flex justify-end">
                <Rating rating={rating} />
              </div>
              {/* <p className="mt-1 text-[8px] font-bold">Write A Review</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsBanner;
