import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import LoginLine from "../../../components/shared/LoginLine";
import axiosInstance from "../../../helper/axios";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import getErrorMsg from "../../../utils/getErrorMsg";
import OrderCalculation from "../OrderCalculation";
import PaypalPayment from "../../../components/shared/PaypalPayment";
import { useGlobalContext } from "../../../hooks/context";

const OrderDetails = () => {
  const { id } = useParams();
  const { auth } = useGlobalContext();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentUpdated, setPaymentUpdated] = useState(false);

  useEffect(() => {
    async function fetchOrder() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get(`/orders/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setOrder(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchOrder();
  }, [auth?.token, id, paymentUpdated]);

  //Todo: Decide what to render
  let content;
  if (isLoading) {
    content = (
      <div className="wrapper">
        <p className="text-3xl">Loading...</p>
      </div>
    );
  } else if (!isLoading && error) {
    content = (
      <div className="wrapper">
        <ErrorMessage message={getErrorMsg(error)} />
      </div>
    );
  } else if (!isLoading && !error && order) {
    content = (
      <>
        <div className="wrapper flex items-center justify-between">
          <p className="text-base font-bold">Invoice ID: {order.invoiceId}</p>
          <p className="text-base font-bold">
            Payment Status: {order?.payment?.status}
          </p>
        </div>
        {order?.payment?.status === "COMPLETED" && (
          <div className="mt-4 wrapper">
            <div
              className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50"
              role="alert"
            >
              <div>
                <span className="font-medium text-3xl">Payment Completed</span>
              </div>
            </div>
          </div>
        )}

        <div className="mt-4 wrapper">
          <div className="bg-secondary-50 px-3 py-2 rounded">
            <h1 className="text-base font-bold text-white text-center">
              Medicine
            </h1>
            <div className="mt-3 grid grid-cols-11">
              <span className="col-span-3 text-xs font-normal text-white text-left">
                Name
              </span>
              <span className="col-span-2 text-xs font-normal text-white text-left">
                Pack size
              </span>
              <span className="col-span-2 text-xs font-normal text-white text-left">
                Rate
              </span>
              <span className="col-span-2 text-xs font-normal text-white text-left">
                QTY
              </span>
              <span className="col-span-2 text-xs font-normal text-white text-right">
                Sub Total
              </span>
            </div>
            {order.products?.map((pd) => (
              <div
                key={pd._id}
                className="mt-3 grid grid-cols-11 gap-x-1 py-5 px-1 rounded bg-white text-black"
              >
                <span className="col-span-3 text-xs font-normal text-left">
                  {pd.name}
                </span>
                <span className="col-span-2 text-xs font-normal text-left">
                  {pd.selectedPackSize.size}
                </span>
                <span className="col-span-2 text-xs font-normal text-left">
                  ${pd.price.toFixed(2)}
                </span>
                <span className="col-span-2 text-xs font-normal text-left">
                  {pd.qty}
                </span>
                <span className="col-span-2 text-xs font-normal text-right">
                  ${pd.subTotal.toFixed(2)}
                </span>
              </div>
            ))}
          </div>
        </div>

        <OrderCalculation
          shippingPrice={order?.shippingPrice}
          bankChargePrice={order?.bankChargePrice}
          gstPrice={order?.gstPrice}
          totalPrice={order?.totalPrice}
        />

        {order?.payment?.status !== "COMPLETED" && (
          <PaypalPayment
            ourOrderId={id}
            totalPrice={order?.totalPrice}
            onPaymentUpdate={() => setPaymentUpdated(true)}
          />
        )}
      </>
    );
  }

  return (
    <div>
      <Layout>
        <LoginLine />
        <div className="my-5">{content}</div>
      </Layout>
    </div>
  );
};

export default OrderDetails;
