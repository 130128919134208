import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  const {
    id,
    name,
    generic,
    availableStrength,
    orginator,
    formulation,
    availablePackSize,
  } = product || {};

  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
      <Link to={`/products/details/${id}`} className="bg-gray-200">
        <img
          className="rounded-t-lg w-full h-40"
          src={availablePackSize[0]?.packImage}
          alt={name}
        />
      </Link>
      <div className="py-5 px-2">
        <Link to={`/products/details/${id}`}>
          <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {name}
          </h5>
        </Link>
        <p className="mt-0.5 text-sm font-semibold text-black">
          Generic:
          <span className="font-medium"> {generic}</span>
        </p>
        <p className="mt-0.5 text-sm font-semibold text-black">
          Available Strength:
          <span className="font-medium"> {availableStrength}</span>
        </p>
        <p className="mt-0.5 text-sm font-semibold text-black">
          Originator:
          <span className="font-medium"> {orginator}</span>
        </p>
        <p className="mt-0.5 text-sm font-semibold text-black">
          Formulation:
          <span className="font-medium"> {formulation}</span>
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
