import React from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";

const ProductCard = ({ product }) => {
  const {
    _id,
    name,
    generic,
    orginator,
    formulation,
    rating,
    availablePackSize,
  } = product || {};
  return (
    <Link to={`/products/details/${_id}`}>
      <div className="w-full bg-white">
        <div className="pt-7 w-full h-[20rem]">
          <img
            src={availablePackSize[0]?.packImage}
            alt={name}
            className="w-full h-full"
          />
        </div>
        <div className="mt-7 pb-5 px-3">
          <div className="w-full h-[1.7px] bg-secondary-200"></div>
          <div className="mt-2 flex justify-between">
            <div>
              <h5 className="text-[10px] font-bold text-black">{name}</h5>
              <p className="text-[8px] font-medium text-gray-700">{generic}</p>
              <p className="text-[8px] font-medium text-gray-700">
                {orginator}
              </p>{" "}
              <p className="text-[8px] font-medium text-gray-700">
                {formulation}
              </p>
            </div>
            <div className="flex flex-col items-end">
              <h5 className="text-[12px] font-bold text-black">
                Price Inquiry
              </h5>
              <p className="text-[8px] font-medium text-gray-700">Reviews</p>
              <Rating rating={rating} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
