import ErrorMessage from "../../ui/ErrorMessage";

const AdminTextArea = ({ id, name, label, error, ...rest }) => {
  return (
    <div className="col-span-1">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-semibold text-gray-900"
        >
          {label}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        {...rest}
      />
      {!!error && <ErrorMessage message={error?.message} />}
    </div>
  );
};

export default AdminTextArea;
