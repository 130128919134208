import React from "react";
import { useGlobalContext } from "../../hooks/context";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AdminRoute = () => {
  const { auth } = useGlobalContext();
  const location = useLocation();

  if (auth && auth.token && auth?.role === "admin") {
    return <Outlet />;
  } else if (auth && auth.token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Navigate to="/login" state={{ from: location }} />;
};

export default AdminRoute;
