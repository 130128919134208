import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/context";

const Search = () => {
  const navigate = useNavigate();
  const { searchValue, handleSeacrh } = useGlobalContext();
  const [search, setSearch] = useState(searchValue);

  const params = useParams();

  const submitHandler = (e) => {
    e.preventDefault();
    handleSeacrh(search.toLowerCase());

    if (params?.category) {
      navigate(`/products/${params.category}`);
    } else {
      navigate("/products/all");
    }
  };

  return (
    <div className="margin-top-3 wrapper font-poppins">
      <form onSubmit={submitHandler}>
        <div className="relative">
          <input
            type="search"
            id="default-search"
            className="py-2 pl-3 block w-full text-[10px] text-gray-900 border border-secondary-200 outline-0 rounded-full bg-white focus:ring-primary-200 focus:border-primary-200"
            placeholder="Search by medicine name / category / generic / orginator"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            type="submit"
            className="px-5 py-[9px] absolute right-0 bottom-0 text-[10px] bg-secondary-50 text-white rounded-r-full outline-0 focus:ring-2 focus:outline-none focus:ring-primary-200"
          >
            Find
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
