import React, { useEffect } from "react";
import LoginForm from "../../components/auth/LoginForm";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useGlobalContext } from "../../hooks/context";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const { auth } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && auth?.token && auth?.role === "admin") {
      navigate("/admin/dashboard");
    } else if (auth && auth.token) {
      navigate("/");
    }
  }, [auth, navigate]);

  // Redirect path
  const redirectPath = location.state?.prevPath
    ? location.state?.prevPath
    : "/";

  console.log(redirectPath);

  return (
    <>
      <Header />
      <section className="min-h-[60vh]">
        <LoginForm
          controlModal={(user) => {
            if (user.role === "admin") {
              navigate("/admin/dashboard");
            } else {
              navigate(redirectPath);
            }
          }}
        />
      </section>
      <Footer />
    </>
  );
};

export default Login;
