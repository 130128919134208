import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import AdminInput from "../shared/admin/AdminInput";
import AdminSelect from "../shared/admin/AdminSelect";
import {
  adminProductFormulationOptions,
  adminProductTypeOptions,
} from "../../data/options";
import AdminTextArea from "../shared/admin/AdminTextArea";
import AvailablePackSize from "./AvailablePackSize";
import { productSchema } from "../../schema/product.schema";
import axiosInstance from "../../helper/axios";
import AdminMultiSelect from "../shared/admin/AdminMultiSelect";

export default function ProductForm({
  defaultValues,
  btnText,
  submit,
  isLoading,
}) {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(productSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const res = await axiosInstance.get("/categories");
        setCategories(res.data?.data);
      } catch (error) {
      } finally {
      }
    }

    fetchCategories();
  }, []);

  const onSubmit = (data) => {
    submit(data);
  };

  const {
    name: nameError,
    category,
    types,
    generic,
    orginator,
    formulation,
    availableStrength,
    application,
    dosage,
    pdDescription,
    indications,
    sideEffect,
    prescribingInformation,
    faqs,
  } = errors || {};

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value, name } }) => (
              <AdminInput
                id={name}
                name={name}
                type="text"
                label="Name"
                value={value || ""}
                onChange={onChange}
                error={nameError}
              />
            )}
          />

          <Controller
            control={control}
            name="category"
            render={({ field: { onChange, value: option, name } }) => (
              <AdminSelect
                id={name}
                name={name}
                label="Category"
                option={option}
                onChange={onChange}
                options={categories}
                error={category?.value}
              />
            )}
          />

          <Controller
            control={control}
            name="types"
            render={({ field: { onChange, value: options, name } }) => (
              <AdminMultiSelect
                id={name}
                name={name}
                label="Types"
                value={options}
                onChange={onChange}
                options={adminProductTypeOptions}
                error={types}
              />
            )}
          />

          <Controller
            control={control}
            name="generic"
            render={({ field: { onChange, value, name } }) => (
              <AdminInput
                id={name}
                name={name}
                type="text"
                label="Generic"
                value={value || ""}
                onChange={onChange}
                error={generic}
              />
            )}
          />

          <Controller
            control={control}
            name="orginator"
            render={({ field: { onChange, value, name } }) => (
              <AdminInput
                id={name}
                name={name}
                type="text"
                label="Orginator"
                value={value || ""}
                onChange={onChange}
                error={orginator}
              />
            )}
          />

          <Controller
            control={control}
            name="formulation"
            render={({ field: { onChange, value: option, name } }) => (
              <AdminSelect
                id={name}
                name={name}
                label="Formulation"
                option={option}
                onChange={onChange}
                options={adminProductFormulationOptions}
                error={formulation?.value}
              />
            )}
          />

          <Controller
            control={control}
            name="availableStrength"
            render={({ field: { onChange, value, name } }) => (
              <AdminInput
                id={name}
                name={name}
                type="text"
                label="Available Strength"
                value={value || ""}
                onChange={onChange}
                error={availableStrength}
              />
            )}
          />
        </div>

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          <Controller
            control={control}
            name="application"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="Application"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={application}
              />
            )}
          />
          <Controller
            control={control}
            name="dosage"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="Dosage"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={dosage}
              />
            )}
          />
          <Controller
            control={control}
            name="pdDescription"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="Product Description"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={pdDescription}
              />
            )}
          />

          <Controller
            control={control}
            name="indications"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="indications"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={indications}
              />
            )}
          />

          <Controller
            control={control}
            name="sideEffect"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="Side Effect"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={sideEffect}
              />
            )}
          />

          <Controller
            control={control}
            name="prescribingInformation"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="Prescribing Information"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={prescribingInformation}
              />
            )}
          />

          <Controller
            control={control}
            name="faqs"
            render={({ field: { onChange, value, name } }) => (
              <AdminTextArea
                id={name}
                name={name}
                type="text"
                label="FAQS"
                rows={4}
                value={value || ""}
                onChange={onChange}
                error={faqs}
              />
            )}
          />
        </div>

        <AvailablePackSize />

        <button
          type="submit"
          className="mt-5 bg-black text-white py-1.5 rounded px-5"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : btnText}
        </button>
      </form>
    </FormProvider>
  );
}
