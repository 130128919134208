import React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InquiyInput from "../../components/ui/InquiyInput";
import ErrorMessage from "../../components/ui/ErrorMessage";
import PhoneInput from "react-phone-input-2";

const options = [
  "How Much is the Price?",
  "How long will it take to deliver? What is the delivery method?",
  "Payment methods",
  "Maximum or minimum order quantity required to place order",
  "What are the documents required to place purchase order?",
];

const schema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("E-mail is required"),

  phone: yup
    .number()
    .min(5)
    .required("Contact number is required")
    .typeError("Contact number is required"),
});

const InquiryForm = ({
  defaultValues,
  submitHandler,
  submitting,
  errMessage,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  // error desturcture
  const { firstName, lastName, email, phone } = errors;

  // submit handler
  const onSubmit = (data) => {
    submitHandler(data, reset);
  };

  const handleGoback = () => {
    window.history.back();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="margin-top-3 w-full bg-[#EAD2E5] px-3 py-2 rounded"
    >
      <h3 className="mt-3 mb-4 flex justify-center font-semibold text-xl">
        Inquiry Form
      </h3>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Customer Name
        </label>
        <div className="grid grid-cols-2 gap-x-2">
          <InquiyInput
            name="firstName"
            type="text"
            placeholder="First Name"
            register={register}
            message={firstName?.message}
          />
          <InquiyInput
            name="lastName"
            type="text"
            placeholder="Last Name"
            register={register}
            message={lastName?.message}
          />
        </div>
      </div>
      <div className="mt-3">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Contact Details
        </label>
        <div className="grid grid-cols-2 gap-x-2">
          <InquiyInput
            name="email"
            type="email"
            placeholder="Email Address"
            register={register}
            message={email?.message}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                value={value}
                defaultCountry="nz"
                country="nz"
                enableSearch={true}
                disableSearchIcon={true}
                countryCodeEditable={false}
                onChange={(phone) => onChange(phone)}
              />
            )}
          />

          {/* <InquiyInput
            name="phone"
            type="tel"
            placeholder="Phone"
            register={register}
            message={phone?.message}
          /> */}
        </div>
      </div>
      <div className="mt-3">
        <h3 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          What do you want to know?
        </h3>
        <div className="grid items-center px-3 py-2 rounded-md border-gray-50 border pb-32">
          {options?.map((option) => (
            <label
              key={option}
              className="block text-gray-500 font-medium italic"
            >
              <input
                type="checkbox"
                className="mr-2 leading-tight"
                value={option}
                {...register("selectedItems")}
              />
              <span className="text-xs my-3">{option}</span>
            </label>
          ))}
          {/* <p className="text-xs">I want to order :</p>
          <div className="ml-3">
            {updatePackSize?.map((option) => (
              <label
                key={option}
                className="block text-gray-500 font-medium italic"
              >
                <input
                  type="checkbox"
                  className="mr-2 leading-tight"
                  value={option}
                  {...register("selectedItems")}
                />
                <span className="text-xs my-3">{option} pack/box/bottles</span>
              </label>
            ))}
          </div> */}
        </div>
      </div>

      <div className="flex flex-wrap mb-6">
        <div className="-mt-28 w-full px-3">
          <h3 className="uppercase mx-auto flex justify-center text-xs font-semibold mb-1">
            Ask here for more information
          </h3>
          <textarea
            rows="7"
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            {...register("moreInfo")}
          ></textarea>
        </div>
        <div className="flex justify-between w-full px-3">
          <button
            onClick={handleGoback}
            className="text-[#2F266B] hover:bg-[#2F266B] border-[#2F266B] border hover:text-white rounded-[20px] font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="text-[#2F266B] hover:bg-[#2F266B] hover:text-white rounded-[20px] border-[#2F266B] border font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
            disabled={submitting}
          >
            {submitting ? "Loading..." : "Submit"}
          </button>
        </div>
        <div className="w-full text-right mt-3">
          <ErrorMessage message={errMessage} />
        </div>
      </div>
    </form>
  );
};

export default InquiryForm;
