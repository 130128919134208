import React, { useState } from "react";
import { useGlobalContext } from "../../hooks/context";
import Register from "../auth/Register";
import LoginModal from "../auth/LoginModal";

const LoginLine = () => {
  const { auth, logOut } = useGlobalContext();
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  // control login modal
  const controlLoginModal = () => {
    setLoginModal((prev) => !prev);
  };

  // control register modal
  const controlRegisterModal = () => {
    setRegisterModal((prev) => !prev);
  };

  // logout handler
  const logoutHandler = () => {
    logOut();
  };

  return (
    <>
      <div className="py-1.5 text-right bg-secondary-50 font-poppins text-[0.7rem]">
        {!auth ? (
          <div className="wrapper text-white">
            <button className="hover:underline" onClick={controlRegisterModal}>
              Register
            </button>
            <button
              className="ml-8 hover:underline"
              onClick={controlLoginModal}
            >
              Login
            </button>
          </div>
        ) : (
          <div className="wrapper flex justify-end text-white">
            <p className="hover:underline">{auth.firstName}</p>
            <button className="ml-8 hover:underline" onClick={logoutHandler}>
              Logout
            </button>
          </div>
        )}
      </div>
      <LoginModal isOpen={loginModal} controlModal={controlLoginModal} />
      <Register isOpen={registerModal} controlModal={controlRegisterModal} />
    </>
  );
};

export default LoginLine;
