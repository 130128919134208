import React from "react";
import HyperModal from "react-hyper-modal";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/context";

const InquirySuccess = ({ isOpen, controlModal }) => {
  const navigate = useNavigate();
  const { emptySelectedItems } = useGlobalContext();

  const modalClose = () => {
    controlModal();
    emptySelectedItems();
    navigate("/");
  };

  return (
    <HyperModal isOpen={isOpen} requestClose={modalClose}>
      <div className="md:flex md:justify-center font-poppins overflow-x-hidden">
        <div className="w-full p-2 py-4 md:py-8 shadow-lg text-center rounded md:px-5">
          <h2 className="text-xl text-center">
            Thank you for your inquiry/registration.
          </h2>
          <p className="mt-5 text-sm font-normal">
            We will contact you at the email address you provided.
          </p>
          <p className="mt-10 text-sm font-semibold">
            Please check your email for an automated response from us. Please
            check your spam/junk folder too! If you do not receive a
            confirmation email within the next hour, it is possible that your
            contact details were entered incorrectly. In that case, please
            contact us at{" "}
            <span className="text-sm font-semibold">
              <a
                href="mailto:info@hopepharmaconnections.co.nz"
                className="text-blue-400 underline"
              >
                info@hopepharmaconnections.co.nz
              </a>
            </span>{" "}
          </p>
        </div>
      </div>
    </HyperModal>
  );
};

export default InquirySuccess;
