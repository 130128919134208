import { useEffect, useState } from "react";
import AdminLayout from "../../../components/layout/AdminLayout";
import ListHeader from "../../../components/shared/ListHeader";
import axiosInstance from "../../../helper/axios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import getErrorMsg from "../../../utils/getErrorMsg";
import ProductForm from "../../../components/product/ProductForm";
import { capitalizeFirstLetter } from "../../../utils/string";
import { useGlobalContext } from "../../../hooks/context";
import { toast } from "react-toastify";

export default function ProductEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [product, setProduct] = useState(null);
  const [editIsLoading, setEditIsLoading] = useState(false);
  const [editError, setEditError] = useState(null);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get(`/products/${id}`);
        setProduct(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, [id]);

  const submit = async (formData) => {
    const data = {
      ...formData,
      category: formData.category.value,
      types: formData.types.map((type) => type.value),
      formulation: formData.formulation.value,
    };

    setEditIsLoading(true);
    setEditError(null);
    try {
      const res = await axiosInstance.patch(`/product/${id}/edit`, data, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (res.status === 200) {
        toast.success(res.data.message);
        navigate(`/admin/products`);
      }
    } catch (error) {
      setEditError(error);
    } finally {
      setEditIsLoading(false);
    }
  };

  // Decide what to render
  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else if (!isLoading && !error && !product) {
    content = <ErrorMessage message="Product not found!" />;
  } else {
    const defaultValues = {
      ...product,
      availablePackSize: product.availablePackSize.map((availablePack) => ({
        size: availablePack.size,
        packSize: availablePack.packSize,
        packName: availablePack.packName,
        price: availablePack.price,
        packImage: availablePack.packImage || null,
      })),
      category: {
        label: capitalizeFirstLetter(product.category),
        value: product.category,
      },
      types: product?.types?.map((type) => ({
        label: capitalizeFirstLetter(type),
        value: type,
      })),
      formulation: {
        label: capitalizeFirstLetter(product.formulation),
        value: product.formulation,
      },
    };

    content = (
      <>
        <ProductForm
          defaultValues={defaultValues}
          btnText="Update"
          submit={submit}
          isLoading={editIsLoading}
        />
        {!!editError && <ErrorMessage message={getErrorMsg(editError)} />}
      </>
    );
  }

  return (
    <AdminLayout>
      <ListHeader title="Product Update" />
      <div className="py-5">{content}</div>
    </AdminLayout>
  );
}
