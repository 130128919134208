import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "../../components/layout";
import { useState } from "react";
import InquiyInput from "../../components/ui/InquiyInput";
import ErrorMessage from "../../components/ui/ErrorMessage";
import SuccessMessage from "../../components/ui/SuccessMessage";
import axios from "../../helper/axios";

const schema = yup.object({
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("E-mail is required"),
});

const ForgotPassword = () => {
  const [submitting, setSubmitting] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { email } = errors;

  const onSubmit = async (data) => {
    const payload = { email: data.email };

    try {
      setSubmitting(true);
      const { data, status } = await axios.post(
        "/auth/forgot-password",
        payload
      );

      if (status === 404 || status === 401) {
        setErrorMessage(data.message);
      }
      if (status === 200) {
        reset();
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="margin-top-3 wrapper font-poppins">
        <div className="h-[50vh] flex items-center justify-center ">
          <form className="mt-3 w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1">
              <InquiyInput
                name="email"
                type="email"
                placeholder="Email Address"
                register={register}
                message={email?.message}
              />
            </div>
            <button
              type="submit"
              className="mt-3 text-secondary-50 hover:bg-secondary-50 border-secondary-50 hover:text-white rounded-[20px] border font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
              disabled={submitting}
            >
              {submitting ? "Loading ..." : "Submit"}
            </button>

            <div className="text-right mt-3">
              <ErrorMessage message={errMessage} />
            </div>
            {success && (
              <SuccessMessage message="We've sent a password reset url to your email." />
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
