import axios from "axios";

// https://hope-pharma-server.onrender.com
// https://hopepharma.azurewebsites.net
// http://localhost:8000

const axiosInstance = axios.create({
  //baseURL: "http://localhost:8005/api",
  baseURL: "https://hopepharma.azurewebsites.net/api",
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // When token date expires then logout
    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      localStorage.removeItem("auth");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
