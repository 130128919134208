import React from "react";
import Layout from "../../components/layout";
import Search from "../../components/shared/Search";
import LoginLine from "../../components/shared/LoginLine";
import Categories from "../../components/shared/Categories";
import NewProducts from "./NewProducts";
// import Testimonials from "./Testimonials";

import "../../css/productSlider.css";

const Home = () => {
  return (
    <Layout>
      <LoginLine />
      <Search />
      <NewProducts />
      <Categories />
      {/* <Testimonials /> */}
    </Layout>
  );
};

export default Home;
