import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className=" py-2 bg-primary-50 font-poppins text-black">
      <div className="wrapper flex justify-between items-center">
        <Link to="/">
          <div className="flex gap-x-1.5">
            <img
              src="/logo/logo.png"
              alt="Home Pharma"
              className="w-[8rem] h-[4rem]"
            />
          </div>
        </Link>
        {/* <div>
          <img
            src="/icon/menu.svg"
            alt="Menubar"
            className="w-[1.5rem] h-[1.5rem]"
          />
        </div> */}
      </div>
    </header>
  );
};

export default Header;
