import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../helper/axios";
import ErrorMessage from "../ui/ErrorMessage";
import getErrorMsg from "../../utils/getErrorMsg";

const Categories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get("/categories-products");
        setCategories(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, []);

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else if (!isLoading && !error && categories?.length === 0) {
    content = <ErrorMessage message="There is no category!" />;
  } else {
    const allProductLength = categories.reduce((acc, cate) => {
      return acc + cate.totalProducts;
    }, 0);

    content = (
      <div className="mt-1.5 grid grid-cols-3 gap-2">
        {categories?.map((cate, index) => {
          return (
            <Link key={cate.category} to={`/products/${cate.category}`}>
              <div
                className={`col-span-1 px-6 pt-7 text-center min-h-[6.6rem] rounded-xl ${
                  index % 2 !== 0
                    ? "bg-primary-100 text-black"
                    : "bg-secondary-100 text-white"
                }`}
              >
                <p className="text-[12px] font-bold capitalize">
                  {cate.category}
                </p>
                <small className="mt-1.5 text-[12px] font-medium">
                  ( {cate.totalProducts} )
                </small>
              </div>
            </Link>
          );
        })}
        <Link to={`/products/all`}>
          <div className="col-span-1 px-6 pt-7 text-center min-h-[6.6rem] rounded-xl bg-secondary-100 text-white">
            <p className="text-[12px] font-bold">All</p>
            <small className="mt-1.5 text-[12px] font-medium">
              ( {allProductLength} )
            </small>
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="margin-top-3 font-poppins wrapper">
      <h5 className="text-[10px] font-bold text-black">Categories</h5>
      {content}
    </div>
  );
};

export default Categories;
