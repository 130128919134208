import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InquiyInput from "../ui/InquiyInput";
import axios from "../../helper/axios";
import { useState } from "react";
import ErrorMessage from "../ui/ErrorMessage";
import { useGlobalContext } from "../../hooks/context";
import { Link } from "react-router-dom";

const schema = yup.object({
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("E-mail is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

const LoginForm = ({ controlModal }) => {
  const { authUpdate } = useGlobalContext();
  const [submitting, setSubmitting] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { email, password } = errors;

  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    try {
      setSubmitting(true);
      const { data, status } = await axios.post("/auth/login", payload);
      if (status === 404 || status === 401) {
        setErrorMessage(data.message);
      }
      if (status === 200) {
        reset();
        authUpdate(data.user);
        modalClose(data.user);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const modalClose = (user) => {
    reset();
    setErrorMessage("");
    controlModal(user);
  };

  return (
    <div className="md:flex md:justify-center font-poppins overflow-x-hidden">
      <div className="w-full p-2 shadow-lg rounded md:px-5">
        <h2 className="text-xl font-semibold text-center">Login</h2>
        <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-3">
            <InquiyInput
              name="email"
              type="email"
              placeholder="Email Address"
              register={register}
              message={email?.message}
            />

            <InquiyInput
              name="password"
              type="password"
              placeholder="Password"
              register={register}
              message={password?.message}
              eyeIconShow={true}
            />
          </div>
          <div className="flex justify-end items-center gap-x-3 mt-3">
            <Link to="/forgot-password" className="text-blue-500">
              Fogot Password?
            </Link>
            <button
              type="submit"
              className="text-secondary-50 hover:bg-secondary-50 border-secondary-50 hover:text-white rounded-[20px] border font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
              disabled={submitting}
            >
              {submitting ? "Loading ..." : "Login"}
            </button>
          </div>
          <div className="text-right mt-3">
            <ErrorMessage message={errMessage} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
