import React, { useState } from "react";
import DeleteButton from "../shared/DeleteButton";
import EditButton from "../shared/EditButton";
import { useGlobalContext } from "../../hooks/context";
import axiosInstance from "../../helper/axios";
import { toast } from "react-toastify";
import getErrorMsg from "../../utils/getErrorMsg";
import { capitalizeFirstLetter } from "../../utils/string";

const ProductItem = ({ product, onUpdateAfterDelete }) => {
  const {
    _id,
    name,
    category,
    types,
    generic,
    packaging,
    orginator,
    formulation,
  } = product || {};
  const { auth } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.delete(`/products/${_id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (res.status !== 200) {
        toast.error("Something went wrong!");
      } else {
        onUpdateAfterDelete(_id);
        toast.success("Product deleted successfully!");
      }
    } catch (error) {
      toast.error(getErrorMsg(error) || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <tr className="bg-white border-b">
      <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
        {name}
      </th>
      <td className="px-6 py-4">{capitalizeFirstLetter(category)}</td>
      <td className="px-6 py-4">
        {types?.map((type) => capitalizeFirstLetter(type))}
      </td>
      <td className="px-6 py-4">{generic}</td>
      <td className="px-6 py-4">{orginator}</td>
      <td className="px-6 py-4">{capitalizeFirstLetter(formulation)}</td>
      <td className="px-6 py-4 flex gap-x-2">
        <EditButton path={`/admin/products/${_id}/edit`} />
        <DeleteButton onDelete={handleDelete} isLoading={isLoading} />
      </td>
    </tr>
  );
};

export default ProductItem;
