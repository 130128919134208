/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { getQueryParams } from "../../utils/queryParams";
import { createProductObject } from "../../utils/productNames";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import jsonProducts from "../../data/productss";
import Product from "./Product";
import axiosInstance from "../../helper/axios";
import ErrorMessage from "../../components/ui/ErrorMessage";
import getErrorMsg from "../../utils/getErrorMsg";

const Products = ({ products, setProducts }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [jsonProducts, setJsonProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const res = await axiosInstance.get("/products");
        setJsonProducts(res.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProducts();
  }, []);

  // all product names
  const productIdsObj = search && getQueryParams(search.replace("?", ""));
  const productIdsArr = createProductObject(productIdsObj);

  // redirect when products is empty
  // products filter by productnames and new property add
  useEffect(() => {
    if (jsonProducts.length > 0) {
      const filterProducts = jsonProducts.filter((pd) => {
        if (productIdsArr.includes(pd._id || pd.id)) {
          return pd;
        }
      });
      if (filterProducts.length === 0) {
        navigate("/");
      } else {
        const qty = 1;
        const tempProducts = filterProducts.map((pd) => {
          let availablePackSize = pd.availablePackSize;
          availablePackSize[0].selected = true;
          const { price } = pd.availablePackSize[0];
          return { ...pd, qty, price, subTotal: price * qty };
        });
        setProducts(tempProducts);
      }
    }
  }, [jsonProducts]);
  // price and total price update with using pack size
  const packSizeHandler = (size, product) => {
    const availablePackSize = product.availablePackSize.map((packSize) =>
      packSize.size === size
        ? { ...packSize, selected: true }
        : { ...packSize, selected: false }
    );

    const { price } = availablePackSize.find(
      (packSize) => packSize.size === size
    );

    const updateProduct = {
      ...product,
      availablePackSize,
      price,
      subTotal: price * product.qty,
    };

    // updateing old products
    const tempProducts = products.map((pd) => {
      if (pd.id === updateProduct.id) {
        return updateProduct;
      }
      return pd;
    });
    setProducts(tempProducts);
  };

  // quantity increase hnadler
  const increase = (productId) => {
    const tempProducts = products.map((pd) => {
      if (pd.id === productId) {
        const qty = pd.qty + 1;
        return { ...pd, qty, subTotal: pd.price * qty };
      }
      return pd;
    });
    setProducts(tempProducts);
  };

  // quantity decrese hnadler
  const decrease = (productId) => {
    const tempProducts = products.map((pd) => {
      if (pd.id === productId) {
        const qty = pd.qty - 1;
        return { ...pd, qty, subTotal: pd.price * qty };
      }
      return pd;
    });
    setProducts(tempProducts);
  };

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!isLoading && error) {
    content = <ErrorMessage message={getErrorMsg(error)} />;
  } else if (!isLoading && !error && jsonProducts?.length === 0) {
    content = <ErrorMessage message="There is no Medicine!" />;
  } else {
    content = (
      <div className="bg-secondary-50 px-3 py-2 rounded">
        <h1 className="text-base font-bold text-white text-center">Product</h1>
        <div className="mt-3 grid grid-cols-11">
          <span className="col-span-3 text-xs font-normal text-white text-left">
            Product name
          </span>
          <span className="col-span-2 text-xs font-normal text-white text-left">
            Pack size
          </span>
          <span className="col-span-2 text-xs font-normal text-white text-left">
            Rate
          </span>
          <span className="col-span-2 text-xs font-normal text-white text-left">
            QTY
          </span>
          <span className="col-span-2 text-xs font-normal text-white text-right">
            Sub Total
          </span>
        </div>
        {products.length > 0 &&
          products.map((product) => (
            <Product
              key={product.id}
              product={product}
              packSizeHandler={packSizeHandler}
              increase={increase}
              decrease={decrease}
            />
          ))}
      </div>
    );
  }

  return <div className="margin-top-3 font-poppins wrapper">{content}</div>;
};

export default Products;
