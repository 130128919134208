import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import ProductDetails from "./pages/details";
import Inquiry from "./pages/inquiry";
import Order from "./pages/order";
import AuthCheck from "./components/auth/AuthCheck";
import TermnCon from "./components/others/TermnCon";
import AboutUs from "./components/others/AboutUs";
import RefundPolicy from "./components/others/RefundPolicy";
import Products from "./pages/products";
import Disclaimer from "./components/others/Disclaimer";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";

import AdminDashboard from "./pages/admin/dashboard";
import ProductList from "./pages/admin/products";
import ProductCreate from "./pages/admin/products/ProductCreate";
import AdminRoute from "./components/auth/AdminRoute";
import Login from "./pages/login/Login";
import ProductEdit from "./pages/admin/products/ProductEdit";
import OrderList from "./pages/admin/orders";
import Orderdetails from "./pages/admin/orders/Orderdetails";
import OrderDetails from "./pages/order/details";
import NotFound from "./pages/notFound";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:userId" element={<ResetPassword />} />
      <Route path="/products/details/:productId" element={<ProductDetails />} />
      <Route path="/products/inquiry" element={<Inquiry />} />
      <Route path="/products/:category" element={<Products />} />
      <Route path="/terms-conditions" element={<TermnCon />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/disclaimer" element={<Disclaimer />} />

      <Route path="/login" element={<Login />} />

      <Route path="/" element={<AuthCheck />}>
        <Route path="/order-placement" element={<Order />} />
        <Route path="/orders/:id" element={<OrderDetails />} />
      </Route>

      {/* Admin Route */}
      <Route path="/" element={<AdminRoute />}>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />

        <Route path="/admin/products/create" element={<ProductCreate />} />
        <Route path="/admin/products/:id/edit" element={<ProductEdit />} />
        <Route path="/admin/products" element={<ProductList />} />

        <Route path="/admin/orders/:id/details" element={<Orderdetails />} />
        <Route path="/admin/orders" element={<OrderList />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
