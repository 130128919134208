import React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HyperModal from "react-hyper-modal";
import InquiyInput from "../ui/InquiyInput";
import axios from "../../helper/axios";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../ui/ErrorMessage";
import { useGlobalContext } from "../../hooks/context";

const schema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("E-mail is required"),
  phone: yup
    .number()
    .min(5)
    .required("Contact number is required")
    .typeError("Contact number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  confirmationPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const Register = ({ isOpen, controlModal }) => {
  const { authUpdate } = useGlobalContext();
  const [submitting, setSubmitting] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { firstName, lastName, email, phone, password, confirmationPassword } =
    errors;

  const onSubmit = async (data) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      password: data.password,
    };
    try {
      setSubmitting(true);
      const { data, status } = await axios.post("/auth/register", payload);
      if (status === 201) {
        reset();
        authUpdate(data.user);
        controlModal();
      }
      if (status === 409) {
        setErrorMessage(data.message);
        // toast.warning(data.message);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response?.data?.message || err.message);
      // toast.warning(err.response?.data?.message || err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const modalClose = () => {
    reset();
    setErrorMessage("");
    controlModal();
  };

  return (
    <HyperModal isOpen={isOpen} requestClose={modalClose}>
      <div className="md:flex md:justify-center font-poppins overflow-x-hidden">
        <div className="w-full p-2 shadow-lg rounded md:px-5">
          <h2 className="text-xl font-semibold text-center">Register</h2>
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              <InquiyInput
                name="firstName"
                type="text"
                placeholder="First name"
                register={register}
                message={firstName?.message}
              />
              <InquiyInput
                name="lastName"
                type="text"
                placeholder="Last name"
                register={register}
                message={lastName?.message}
              />
              <InquiyInput
                name="email"
                type="email"
                placeholder="Email Address"
                register={register}
                message={email?.message}
              />
              <div className="col-span-1">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      value={value}
                      defaultCountry="nz"
                      country="nz"
                      enableSearch={true}
                      disableSearchIcon={true}
                      countryCodeEditable={false}
                      onChange={(phone) => onChange(phone)}
                    />
                  )}
                />
                {phone?.message && <ErrorMessage message={phone?.message} />}
              </div>
              <InquiyInput
                name="password"
                type="password"
                placeholder="Password"
                register={register}
                message={password?.message}
                eyeIconShow={true}
              />
              <InquiyInput
                name="confirmationPassword"
                type="password"
                placeholder="Confirmation password"
                register={register}
                message={confirmationPassword?.message}
                eyeIconShow={true}
              />
            </div>
            <div className="text-right mt-3">
              <button
                type="submit"
                className="text-secondary-50 hover:bg-secondary-50 border-secondary-50 hover:text-white rounded-[20px] border font-medium py-1.5 px-7 focus:outline-none focus:shadow-outline"
                disabled={submitting}
              >
                {submitting ? "Loading ..." : "Register"}
              </button>
            </div>
            <div className="text-right mt-3">
              <ErrorMessage message={errMessage} />
            </div>
          </form>
        </div>
      </div>
    </HyperModal>
  );
};

export default Register;
