import React from "react";
import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../components/ui/ErrorMessage";

const Decleration = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { certifyAndDeclare } = errors;

  return (
    <div className="margin-top-3 font-poppins wrapper">
      <div className="bg-primary-50 px-3 py-2 rounded">
        <h1 className="text-base font-bold text-black text-center">
          Declaration
        </h1>
        <div className="margin-top-3 px-3 py-2 rounded border border-white text-center mx-auto">
          <div className="flex items-center gap-x-3 justify-center">
            <input
              type="checkbox"
              id="certifyAndDeclare"
              name="certifyAndDeclare"
              className="text-base"
              {...register("certifyAndDeclare")}
            />
            <label className="text-base mt-[2px]" htmlFor="certifyAndDeclare">
              I certify and declare
            </label>
          </div>
          {certifyAndDeclare?.message && (
            <ErrorMessage message={certifyAndDeclare?.message} />
          )}
          <p className="text-base text-center mt-5">
            I certify that the information given is true and complete to the
            best of my knowledge. I understand that if I have deliberately given
            any false information or have withheld any information regarding any
            situation, I am liable for prosecution for fraud and/or perjury.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Decleration;
